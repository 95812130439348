import { Box } from '@mui/material';

export const CustomTabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && <Box sx={{ p: 3, padding: 0 }}>{children}</Box>}
  </div>
);
