import { Box, FormControl, FormLabel, MenuItem, Select, styled } from '@mui/material';
import { capitalize } from 'lodash';
import { ArrowDownIcon } from '../assets';

export const Dropdown = ({
  value,
  options,
  startIcon,
  onChange,
  label,
  defaultValueLabel,
  displayEmpty = false,
  disabled,
}) => (
  <StyledFormControl disabled={disabled}>
    {displayEmpty && <StyledLabel>{label}</StyledLabel>}
    <StyledSelect
      IconComponent={() => <ArrowDownIcon />}
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      displayEmpty
      renderValue={() => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {options[value]?.icon || startIcon}
          {capitalize(options[value]?.label || `${defaultValueLabel}`)}
        </Box>
      )}
    >
      {displayEmpty && <StyledMenuItem value={null}>{defaultValueLabel}</StyledMenuItem>}
      {Object.entries(options).map(([key, value]) => (
        <StyledMenuItem sx={{ gap: '16px' }} value={key}>
          {value?.icon}
          {value?.label}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  </StyledFormControl>
);

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  '& .MuiInputBase-root': {
    '& fieldset': {
      border: `1px solid ${theme.palette.grey[600]}`,
      borderRadius: '8px',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.action.focus,
    },
  },
}));

const StyledLabel = styled(FormLabel)(({ theme }) => ({
  ...theme.typography.label,
  color: theme.palette.grey[600],
  display: 'flex',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  background: theme.palette.common.white,
  width: '100%',
  padding: `12px ${theme.spacing(2)}`,

  '& .MuiSelect-select': {
    padding: 0,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  padding: `12px ${theme.spacing(2)}`,
}));
