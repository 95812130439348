import { BorrowedSearchStatusIcon, FreeSearchStatusIcon, LegacySearchStatusIcon } from './assets';

export const Location = {
  ZAGREB: { label: 'Zagreb' },
  OSIJEK: { label: 'Osijek' },
  SPLIT: { label: 'Split' },
};

export const Statuses = {
  FREE: { label: 'Available', icon: <FreeSearchStatusIcon /> },
  BORROWED: { label: 'Borrowed', icon: <BorrowedSearchStatusIcon /> },
  IN_A_BOX: { label: 'Legacy device', icon: <LegacySearchStatusIcon /> },
  NOT_WORKING: { label: 'Not working', icon: <LegacySearchStatusIcon /> },
  IN_REPAIR: { label: 'In repair', icon: <LegacySearchStatusIcon /> },
  NOT_FOUND: { label: 'Lost', icon: <LegacySearchStatusIcon /> },
};

export const TableColumns = [
  { label: 'Internal ID', value: 'internalId' },
  { label: 'Model', value: 'model' },
  { label: 'OS Version', value: 'osVersion' },
  { label: 'Platform', value: 'devicePlatform' },
  { label: 'Location', value: 'currentLocation' },
  { label: 'Status', value: 'deviceStatus' },
  { label: '', value: '' },
];
