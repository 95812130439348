import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { ActionButton, Button } from 'core/ui';
import { CheckCircleIcon, TimesCircleIcon } from 'core/assets';
import { useNavigate } from 'react-router-dom';
import { LoanVariant } from '../constants';
import { approveLoan as handleApproveClick, declineLoan as handleDeclineClick } from '../api';

export const DevicesTable = ({ variant, devices, refreshPage }) => {
  const { tableValues } = LoanVariant[variant];
  const navigate = useNavigate();
  const handleClickRow = (id) => {
    navigate(`/device-detail/${id}`);
  };

  return (
    <Table>
      <StyledTableHead>
        <StyledTableRow>
          {tableValues.map((headerCell) => (
            <StyledTableCell variant={variant} header={headerCell.header}>
              <StyledTypographyHeader>{headerCell.header}</StyledTypographyHeader>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      </StyledTableHead>
      <TableBody>
        {devices.map((device) => (
          <StyledTableRow key={device.Id}>
            {tableValues.map((headerCell) => (
              <StyledTableCell
                variant={variant}
                header={headerCell.header}
                noPadding={!headerCell.header}
              >
                {!headerCell.header ? (
                  headerCell.value === 'actionButtons' ? (
                    <StyledButtonContainer disableGutters>
                      <ActionButton
                        onClick={() => handleApproveClick(device, refreshPage)}
                        label="Approve"
                        icon={<CheckCircleIcon />}
                        color="success"
                      />
                      <ActionButton
                        onClick={() => handleDeclineClick(device, refreshPage)}
                        label="Decline"
                        icon={<TimesCircleIcon />}
                        color="error"
                      />
                    </StyledButtonContainer>
                  ) : (
                    <Button
                      onClick={() => handleClickRow(device.deviceId)}
                      label="Manage"
                      variant="secondary"
                    />
                  )
                ) : (
                  <StyledTypographyBody> {device[headerCell.value]}</StyledTypographyBody>
                )}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const StyledButtonContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  alignItems: 'center',
  gap: theme.spacing(2),
  width: 'auto',
}));

const StyledTableHead = styled(TableHead)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.grey[400]}`,
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
  alignItems: 'center',
  gap: theme.spacing(1),
  alignSelf: 'stretch',
}));

const StyledTypographyHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  color: theme.palette.grey[600],
}));
const StyledTypographyBody = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  color: theme.palette.common.black,
}));

const StyledTableCell = styled(TableCell)(({ theme, noPadding, variant, header }) => ({
  display: 'flex',
  padding: noPadding ? 0 : theme.spacing(1),
  alignItems: 'flex-start',
  gap: '12px',
  flex: '1 0 0',
  borderBottom: 'none',
  minWidth: `${variant === 'PENDING_REQUEST' ? (header === '' ? '268px' : '105px') : 'auto'}`,
  maxWidth: `${variant === 'PENDING_REQUEST' ? (header === '' ? '268px' : '105px') : 'auto'}`,
}));
