import React from 'react';
import { Grid } from '@mui/material';
import {
  Label,
  ErrorText,
  MeasurmentInputContainer,
  TooltipCard,
  TooltipText,
  TooltipBox,
  InputContent,
} from './styled';
import DropdownInput from './DropdownInput';
import { dropdownOptions } from '../../input_data';
import TextInput from './TextInput';

const MeasurementInput = ({
  name,
  unitName,
  errors = {},
  validationSchema,
  register = () => {},
  required = false,
}) => (
  <MeasurmentInputContainer>
    {required ? (
      <TooltipCard>
        <TooltipText>
          <Label>*Size</Label>
        </TooltipText>
        <TooltipBox>
          <p>
            This is <strong>required</strong> field.
          </p>
        </TooltipBox>
      </TooltipCard>
    ) : (
      <Label>Size</Label>
    )}
    <InputContent>
      <Grid container>
        <Grid item xs={6}>
          <TextInput
            style={{ padding: '0px', borderLeft: '0px' }}
            placeholder="0"
            type="text"
            register={() => register(name, validationSchema)}
          />
        </Grid>
        <Grid item xs={6}>
          <DropdownInput
            options={dropdownOptions.measureUnit}
            register={() => register(unitName, validationSchema)}
          />
        </Grid>
      </Grid>
    </InputContent>
    {errors[name]?.message && <ErrorText>Size:{errors[name].message}</ErrorText>}
    {errors[unitName]?.message && (
      <ErrorText>Size measuring unit:{errors[unitName].message}</ErrorText>
    )}
  </MeasurmentInputContainer>
);

export default MeasurementInput;
