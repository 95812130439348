import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { Input, InputContainer, Label, ErrorText } from './styled';

const TextInput = ({
  disabled,
  label,
  name,
  type,
  value,
  min,
  placeholder,
  validationSchema,
  style,
  errors = {},
  onChange = () => {},
  register = () => {},
  required = false,
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    if (required) {
      setOpen(!open);
    }
  };

  return (
    <InputContainer>
      <Tooltip
        open={open}
        onClose={toggleOpen}
        onOpen={toggleOpen}
        title="This is required field!"
        placement="top"
      >
        {label && (
          <Label>
            {required && <>*</>}
            {label}
          </Label>
        )}
      </Tooltip>
      <Input
        disabled={disabled}
        style={style}
        name={name}
        type={type}
        min={min}
        value={value}
        placeholder={placeholder}
        inputProps={{ pattern: '[a-z]' }}
        onChange={onChange}
        {...register(name, validationSchema)}
      />
      {errors[name]?.message && <ErrorText>{errors[name].message}</ErrorText>}
    </InputContainer>
  );
};
export default TextInput;
