import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';
import PropTypes from 'prop-types';

const IconColor = {
  GREEN: 'green',
  YELLOW: 'yellow',
  BLUE: 'blue',
  GREY: 'grey',
};

export const SvgIcon = ({ icon, isLarge, color }) => (
  <StyledSvg isLarge={isLarge} color={color}>
    {icon}
  </StyledSvg>
);

SvgIcon.propTypes = {
  icon: PropTypes.elementType.isRequired,
  isLarge: PropTypes.bool,
  color: PropTypes.string,
};
SvgIcon.defaultProps = {
  isLarge: false,
  color: '',
};

const resolveIconColor = (color, palette) => {
  switch (color) {
    case IconColor.GREEN:
      return palette.icon.darkGreen;
    default:
      return '';
  }
};
const resolveBackgroundIconColor = (color, palette) => {
  switch (color) {
    case IconColor.YELLOW:
      return palette.icon.lightYellow;
    case IconColor.GREEN:
      return palette.icon.lightGreen;
    case IconColor.BLUE:
      return palette.icon.lightBlue;
    case IconColor.GREY:
      return palette.grey[400];
    default:
      return '';
  }
};

const StyledSvg = styled(MuiSvgIcon)(({ theme: { palette }, color, isLarge }) => ({
  color: `${resolveIconColor(color, palette)}`,
  backgroundColor: `${resolveBackgroundIconColor(color, palette)}`,
  borderRadius: '8px',
  padding: isLarge ? '4px' : '8px',
  gap: '4px',
  fontSize: isLarge ? '24px' : '16px',
}));
