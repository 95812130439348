import { Box, styled } from '@mui/material';
import { Api } from 'api/Api';
import { useAuth } from 'context/auth/AuthProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { baseURL, employeeUrl, getCurrentUser, updateMyLocationURL } from 'SharedAddresses';
import { Button, CreateNewMessage, Loading, Paper } from 'core/ui';
import { useState } from 'react';
import { capitalize, first } from 'lodash';
import { LocationDropdown } from 'core/components';
import { v4 as uuidv4 } from 'uuid';
import { Row } from './components';
import { LogoutIcon } from './assets';

export const ProfilePage = () => {
  const apiClient = new Api();
  const { email, logout } = useAuth();
  const queryClient = useQueryClient();
  const [location, setLocation] = useState();

  const { isLoading, error, data } = useQuery({
    queryKey: ['user'],
    queryFn: () => apiClient.post(baseURL + getCurrentUser, {}).then((res) => res.data),
    onSuccess: (data) => {
      setLocation(data.location);
    },
  });

  const employeeLocationMutation = useMutation({
    mutationFn: (newLocation) =>
      apiClient.put(baseURL + employeeUrl + updateMyLocationURL, null, {
        'new-location': newLocation,
      }),
    onSuccess: () => {
      CreateNewMessage({
        title: 'Team name updated',
        body: 'Your changes have been applied successfully.',
        id: uuidv4(),
        color: 'success',
      });
    },
    onError: (error) => {
      CreateNewMessage({
        title: 'Not able to update team name.',
        body: [error.message],
        id: uuidv4(),
        color: 'error',
      });
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  if (error) return `An error has occurred: ${error.message}`;

  if (isLoading) {
    return <Loading />;
  }

  const handleLogoutClick = () => {
    logout();
  };

  const handleLocationChange = (location) => {
    employeeLocationMutation.mutate(location);
    setLocation(location);
  };

  const dataForDisplay = [
    { label: 'Email', value: email },
    { label: 'Username', value: data.username },
    { label: 'Name', value: data.name },
  ];

  return data ? (
    <Paper
      icon={<LetterIconBox>{capitalize(first(data.name))}</LetterIconBox>}
      title={`${data.name} ${data.surname}`}
      rightButtons={<Button label="Logout" icon={<LogoutIcon />} onClick={handleLogoutClick} />}
    >
      {dataForDisplay.map(({ label, value }) => (
        <Row label={label} value={value} />
      ))}
      <Row
        label="Location"
        value={
          <LocationDropdown
            value={location || ''}
            onChange={handleLocationChange}
            disabled={employeeLocationMutation.isLoading}
          />
        }
      />
    </Paper>
  ) : null;
};

const LetterIconBox = styled(Box)(({ theme: { palette } }) => ({
  width: '32px',
  height: '32px',
  backgroundColor: palette.info.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '6.4px',
  color: palette.info.main,
  fontWeight: 700,
  fontSize: '19.2px',
}));
