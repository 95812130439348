/* eslint-disable class-methods-use-this */
import axios from 'axios';

export class Api {
  getHeaders() {
    const username = localStorage.getItem('email');
    const password = localStorage.getItem('password');
    const auth = `Basic ${btoa(`${username}:${password}`)}`;

    return {
      Authorization: auth,
    };
  }

  get(url, params = {}) {
    return axios.get(url, {
      headers: this.getHeaders(),
      params,
    });
  }

  post(url, data, params = {}) {
    return axios.post(url, data, {
      headers: this.getHeaders(),
      params,
    });
  }

  put(url, data, params = {}) {
    return axios.put(url, data, {
      headers: this.getHeaders(),
      params,
    });
  }

  delete(url) {
    return axios.delete(url, {
      headers: this.getHeaders(),
    });
  }
}
