import PropTypes from 'prop-types';
import { Paper } from 'core/ui';
import { LoanVariant } from '../constants';
import { Placeholder } from './Placeholder';
import { DevicesTable } from './DevicesTable';

export const TableContainer = ({ variant, devices, refreshPage }) => {
  const { icon, title, message, labelIcon, label } = LoanVariant[variant];

  return (
    <Paper title={label} icon={labelIcon}>
      {devices.length === 0 ? (
        <Placeholder variant={variant} icon={icon} title={title} message={message} />
      ) : (
        <DevicesTable refreshPage={refreshPage} variant={variant} devices={devices} />
      )}
    </Paper>
  );
};

TableContainer.propTypes = {
  variant: PropTypes.string.isRequired,
};
