import React from 'react';
import styled from 'styled-components';
import chevronLeft from '../../images/chevron-left.svg';
import chevronsLeft from '../../images/chevrons-left.svg';
import chevronRight from '../../images/chevron-right.svg';
import chevronsRight from '../../images/chevrons-right.svg';

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 0.8rem;
  }
`;

const enabled = `
cursor: pointer;
background-color: #282c34;
transition: background-color 0.2s;

&:hover {
  background-color: black;
}

&:active {
  background-color: #282c34;
}
`;

const disabled = `
  background-color: grey;
`;

const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 50px;
  img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(175deg) brightness(121%)
      contrast(114%);
  }
  ${(props) => (props.disabled ? disabled : enabled)};
`;

const PaginationLabel = styled.label`
  font-size: 1rem;
`;

const TablePagination = ({ data, activePage, setActivePage }) => {
  const getLastPage = () => data.numberOfPages - 1;
  const atFirstPage = () => activePage === 0;
  const atLastPage = () => activePage === getLastPage();

  const goToFirstPage = () => setActivePage(0);
  const goToLastPage = () => setActivePage(getLastPage());
  const incrementPage = () => activePage < getLastPage() && setActivePage(activePage + 1);
  const decrementPage = () => activePage > 0 && setActivePage(activePage - 1);

  return (
    <PaginationContainer>
      <PaginationButton onClick={() => goToFirstPage()} disabled={atFirstPage()}>
        <img src={chevronsLeft} alt="go to first page icon" />
      </PaginationButton>
      <PaginationButton onClick={() => decrementPage()} disabled={atFirstPage()}>
        <img src={chevronLeft} alt="go to previous page icon" />
      </PaginationButton>
      <PaginationLabel>{activePage + 1}</PaginationLabel>
      <PaginationButton onClick={incrementPage} disabled={atLastPage()}>
        <img src={chevronRight} alt="go to next page icon" />
      </PaginationButton>
      <PaginationButton onClick={goToLastPage} disabled={atLastPage()}>
        <img src={chevronsRight} alt="go to last page icon" />
      </PaginationButton>
    </PaginationContainer>
  );
};

export default TablePagination;
