import { Button, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';

const ButtonColor = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const ActionButton = ({ icon, isDisabled, label, onClick, isLoading, color }) => (
  <StyledButton
    onClick={onClick}
    color={color}
    isLoading={isLoading}
    disabled={isDisabled}
    startIcon={icon}
  >
    <StyledTypography>{label}</StyledTypography>
  </StyledButton>
);

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

ActionButton.defaultProps = {
  isLoading: false,
  isDisabled: false,
  color: ButtonColor.SUCCESS,
};

const StyledButton = styled(Button)(({ theme: { palette }, color, isLoading, disabled }) => ({
  color: palette.grey[900],
  backgroundColor: isLoading ? `${palette[color].light}66` : palette.common.white,
  height: '40px',
  textTransform: 'none',
  border: `2px solid ${palette[color].main}${isLoading ? '66' : ''}`,
  opacity: disabled ? 0.6 : 1,
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: palette[color].light,
  },
  '&:focus': {
    borderColor: palette.action.focus,
  },
  '& .MuiButton-startIcon': {
    color: palette[color].main,
  },
}));

const StyledTypography = styled(Typography)({
  lineHeight: '24px',
  fontWeight: 600,
});
