// export const baseURL = "http://localhost:8080";
export const baseURL = process.env.REACT_APP_API_URL;

export const devicesURL = '/device/';
export const updateTimestampAdminURL = 'update-admin-timestamp/';
export const getQrCode = 'get-qr-code/';
export const createNewDeviceURL = 'create';
export const updateDeviceByAdminURL = 'update-admin/';
export const deviceUpdateByEmployeeURL = 'update-employee/';
export const getDevicesByPageURL = 'all';
export const getDevicesByLocationInPageURL = 'all';
export const updateDeviceStatusURL = 'update-status/';

export const getDeviceNotes = '/device-note/device-id/';
export const deviceNotesUrl = '/device-note/create-by-employee/';
export const updateResolveStatusOnNote = '/device-note/update-resolve/';

export const requestFromAdmin = '/checkout/from-admin';
export const checkoutURL = '/checkout/to-employee';
export const getLoans = '/loan/loan-to-confirm';
export const confirmLoanAdmin = '/confirm-loan/admin';
export const confirmLoanEmployee = '/confirm-loan/by-id';
export const teamAdminApprove = '/checkout/approve';
export const returnLoan = '/confirm-loan/return';
export const returnURL = '/checkout/return/';
export const declineRequestURL = '/checkout/decline-loan/';
export const loanFromCurrentEmployee = '/loan/from-current-user';
export const extendLoan = '/loan/extend';

export const employeeUrl = '/employee';
export const allEmployeesURL = '/all';
export const allEmployeesByPageURL = '/all-by-page';
export const employeeDetailURL = '/get-employee-by-id/';
export const getNewPassword = '/employee/get-new-password';
export const getCurrentUser = '/employee/current';
export const createNewEmployeeURL = '/employee/create/';
export const updateMyLocationURL = '/update-location';
export const updateEmployeeInfoURL = '/update-info/';
export const updateEmployeeLocationURL = '/update-location/';
export const clearAllLoansURL = '/clear-all-loans/';
export const addNewRoleToEmployeeURL = '/add-new-role/';
export const addNewTemporearyAdminRoleToEmployeeURL = '/add-new-temporary-admin/';
export const removeRoleFromEmployeeURL = '/remove-role/';
export const updatePasswordURL = '/update-password';
export const updateEmployeeTeamURL = '/update-team/';

export const historyOfDevice = '/history/get-loans-by-time-and-device?device-id=';

export const getAllTeamsURL = '/team/all';
export const teamUrl = '/team/';
export const createTeamURL = 'create';

export const excelURL = '/excel';
export const excelEmployeeInputURL = '/upload-employees';
export const excelDeviceInputURL = '/upload-devices';
