import {
  Container,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDateToString } from '../../core/util/dateFormatUtil';

const tableConfig = [
  { label: 'Borrower name', property: 'fullName' },
  { label: 'Loan start', property: 'timeStartLoan' },
  { label: 'Loan end', property: 'timeEndLoan' },
];
export const DeviceHistory = ({ deviceHistoryData }) => {
  const mappingTableDeviceHistory = deviceHistoryData.map((it) => ({
    fullName: `${it.employeeBorrowingName} ${it.employeeBorrowingSurname}`,
    timeStartLoan: formatDateToString(it.timeStartLoan),
    timeEndLoan: formatDateToString(it.timeEndLoan),
  }));

  return (
    <Table>
      <StyledTableHead>
        <StyledTableRow>
          {tableConfig.map((headerCell) => (
            <StyledTableCell>
              <StyledTypographyHeader>{headerCell.label}</StyledTypographyHeader>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      </StyledTableHead>
      <TableBody>
        {mappingTableDeviceHistory.map((device) => (
          <StyledTableRow key={device.id}>
            {tableConfig.map((headerCell) => (
              <StyledTableCell>
                <StyledTypographyBody> {device[headerCell.property]}</StyledTypographyBody>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const StyledTableHead = styled(TableHead)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.grey[400]}`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
  alignItems: 'center',
  gap: theme.spacing(1),
  alignSelf: 'stretch',
}));

const StyledTypographyHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  color: theme.palette.grey[600],
}));
const StyledTypographyBody = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  color: theme.palette.common.black,
}));

const StyledTableCell = styled(TableCell)(({ theme, noPadding }) => ({
  display: 'flex',
  padding: noPadding ? 0 : theme.spacing(1),
  alignItems: 'flex-start',
  gap: '12px',
  flex: '1 0 0',
  borderBottom: 'none',
  minWidth: 'auto',
  maxWidth: 'auto',
}));
