import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export const useDebounce = (initialValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  const debouncedUpdate = debounce((value) => {
    setDebouncedValue(value);
  }, delay);

  useEffect(() => {
    debouncedUpdate(initialValue);

    return () => {
      debouncedUpdate.cancel();
    };
  }, [initialValue, delay]);

  return debouncedValue;
};
