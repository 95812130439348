import { Typography, styled } from '@mui/material';
import { MaterialDesignContent, enqueueSnackbar } from 'notistack';

export const CreateNewMessage = (message) => {
  enqueueSnackbar(
    <div>
      <StyledTypographyHeading>{message.title} </StyledTypographyHeading>
      {!!message.body && <StyledTypographyBody> {message.body} </StyledTypographyBody>}
    </div>,
    { variant: message.color },
  );
};

const StyledTypographyHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  lineHeight: '20px',
  fontSize: '14px',
}));

const StyledTypographyBody = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 500,
  fontSize: '14px',
}));

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.light,
    border: `1px solid ${theme.palette.success.main}66`,
    color: theme.palette.success.contrastText,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.light,
    border: `1px solid ${theme.palette.error.main}66`,
    color: theme.palette.error.contrastText,
  },
}));
