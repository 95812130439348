import React, { useLayoutEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  baseURL,
  excelDeviceInputURL,
  excelEmployeeInputURL,
  excelURL,
} from '../../SharedAddresses';
import { Api } from '../../api/Api';
import FormContainer from '../../components/forms/FormContainer';
import { FileInput } from '../../components/inputs/FileInput';
import CenteredSpinner from '../../components/loaders/Loader';
import notificationStore from '../../messages/Notifications';
import { AlertMessages } from '../../messages/AlertMessage';
import { Div } from './styled';

export const ExcelImportPage = () => {
  const [state, setState] = useState({
    uploading: false,
    excelFileName: '',
  });
  const [chatState, setChatState] = useState(notificationStore.initialState);

  // //////////////////////////////////////////   rxjs messages

  useLayoutEffect(() => {
    notificationStore.subscribe(setChatState);
    notificationStore.init();
  }, []);

  const handleCloseAfterFiveSeconds = (id) => {
    setTimeout(() => {
      notificationStore.clearMessage(id);
    }, 10000);
  };

  // ////////////////////// save excel employees

  const handleSubmitExcelEmployees = (data) => {
    const apiClient = new Api();
    setState((prev) => ({
      ...prev,
      uploading: true,
      excelFileName: data.inputFileEmployee[0].name,
    }));
    const formData = new FormData();
    formData.append('file', data.inputFileEmployee[0], data.inputFileEmployee[0].name);
    apiClient
      .post(baseURL + excelURL + excelEmployeeInputURL, formData)
      .then((resp) => {
        const messageObject = {
          title: 'Inputed employees through excel',
          text: resp.data.effectedRows,
          id: uuidv4(),
          variant: 'success',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);

        setState((prev) => ({
          ...prev,
          uploading: false,
        }));
      })
      .catch(() => {
        const messageObject = {
          title: 'Employee input excel',
          text: ['Something went wrong'],
          id: uuidv4(),
          variant: 'danger',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);

        setState((prev) => ({
          ...prev,
          uploading: false,
        }));
      });
  };

  const handleSubmitExcelDevices = (data) => {
    const apiClient = new Api();
    setState((prev) => ({
      ...prev,
      uploading: true,
      excelFileName: data.inputFileDevice[0].name,
    }));
    const formData = new FormData();
    formData.append('file', data.inputFileDevice[0], data.inputFileDevice[0].name);
    apiClient
      .post(baseURL + excelURL + excelDeviceInputURL, formData)
      .then((resp) => {
        const messageObject = {
          title: 'Inputed devices through excel',
          text: resp.data.effectedRows,
          id: uuidv4(),
          variant: 'success',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);

        setState((prev) => ({
          ...prev,
          uploading: false,
        }));
      })
      .catch(() => {
        const messageObject = {
          title: 'Device input excel',
          text: ['Something went wrong'],
          id: uuidv4(),
          variant: 'danger',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);

        setState((prev) => ({
          ...prev,
          uploading: false,
        }));
      });
  };

  if (state.uploading) {
    return <CenteredSpinner />;
  }
  return (
    <Div>
      <FormContainer
        defaultValues={{ inputFileEmployee: '' }}
        onSubmit={handleSubmitExcelEmployees}
      >
        <FileInput
          label="Upload employees excel file"
          name="inputFileEmployee"
          type="file"
          placeholder="Upload file"
          validationSchema={{ required: 'This is required!' }}
        />
        <button type="submit"> Upload excel </button>
      </FormContainer>
      <FormContainer onSubmit={handleSubmitExcelDevices} defaultValues={{ inputFileDevice: '' }}>
        <FileInput
          label="Upload device excel file"
          name="inputFileDevice"
          type="file"
          placeholder="Upload file"
          validationSchema={{ required: 'This is required!' }}
        />
        <button type="submit"> Upload excel </button>
      </FormContainer>

      {chatState.data.map((message, index) => (
        <AlertMessages
          message={message}
          key={index}
          closeMessage={notificationStore.clearMessage}
        />
      ))}
    </Div>
  );
};
