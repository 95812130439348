import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from '@mui/material';
import { IconHeadline } from './IconHeadline';
import { CloseIcon } from '../assets';

export const RequestedDeviceResponceStatus = ({
  variant,
  deviceModel,
  handleClose,
  icon,
  open,
  headlineIcon,
  deviceOsVersion,
  deviceName,
  devicePlatform,
  title,
  textBody,
}) => (
  <StyledDialog onClose={handleClose} open={open}>
    <StyledXContainer>
      <CloseIcon onClick={handleClose} />
    </StyledXContainer>
    <StyledIconContainer>{icon}</StyledIconContainer>
    <DialogTitle sx={{ padding: 0 }}>
      <StyledTitle>{title}</StyledTitle>
      <StyledBody>{textBody}</StyledBody>
    </DialogTitle>
    {variant === 'success' && (
      <StyledDialogBody>
        <StyledTitleOfDialogBody>
          <IconHeadline label={deviceModel} icon={headlineIcon} />
          <Typography variant="body2" sx={{ fontWeight: 500, whiteSpace: 'pre' }}>
            {devicePlatform} {deviceOsVersion}
          </Typography>
        </StyledTitleOfDialogBody>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {deviceName}
        </Typography>
      </StyledDialogBody>
    )}
  </StyledDialog>
);

const StyledTitleOfDialogBody = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
});
const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  width: '338px',
  color: theme.palette.common.black,
}));

const StyledBody = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.grey[600],
  fontWeight: 500,
  width: '591px',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    display: 'flex',
    margin: 0,
    padding: theme.spacing(3),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  },
}));

const StyledIconContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(2)} 0px`,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  alignSelf: 'stretch',
}));

const StyledXContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(2)} 0px`,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '4px',
  alignSelf: 'stretch',
}));

const StyledDialogBody = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(2)} !important`,
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  alignSelf: 'stretch',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[400]}`,
}));
