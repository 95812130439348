import { Container, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Api } from 'api/Api';
import { baseURL, getLoans, loanFromCurrentEmployee } from 'SharedAddresses';
import { Loading } from 'core/ui';
import { TableContainer } from './components';

export const HomePage = () => {
  const [devicesToApprove, setDevicesToApprove] = useState([]);
  const [employeesDevices, setEmployeesDevices] = useState([]);
  const [refreshPage, setRefreshPage] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const apiClient = new Api();
    apiClient.get(baseURL + getLoans).then((response) => {
      setDevicesToApprove(response.data);
      setLoading(false);
    });
    apiClient.get(baseURL + loanFromCurrentEmployee).then((response) => {
      setEmployeesDevices(response.data);
    });
  }, [refreshPage]);

  return (
    <StyledMainContainer disableGutters>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TableContainer
            refreshPage={() => setRefreshPage(!refreshPage)}
            variant="PENDING_REQUEST"
            devices={devicesToApprove}
          />
          <TableContainer
            refreshPage={() => setRefreshPage(!refreshPage)}
            variant="BORROWED_DEVICES"
            devices={employeesDevices}
          />
        </>
      )}
    </StyledMainContainer>
  );
};

const StyledMainContainer = styled(Container)(({ theme }) => ({
  gap: theme.spacing(3),
  display: 'flex',
  width: '1200px',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexShrink: 0,
  background: theme.palette.grey[300],
}));
