import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SidebarMenu } from 'features/navigation';
import { DevicesList } from 'features/devices';
import { HomePage } from 'features/home-page';
import { styled } from '@mui/material';
import { LoginPage } from 'features/login';
import { DeviceDetail } from 'features/device-detail/DeviceDetail';
import { TeamsPage } from 'features/teams';
import { useAuth } from '../context/auth/AuthProvider';
import { EmployeeList } from '../pages/list-of-employees/EmployeeList';
import { ErrorPage } from '../pages/error/ErrorPage';
import DeviceHistory from '../pages/loan/DeviceHistory';
import { QrScanner } from '../pages/qrScanner/QrScanner';
import { ProtectedRoute } from './ProtectedRoute';
import { ExcelImportPage } from '../pages/import-excel/ExcelImportPage';
import AddEmployee from '../pages/list-of-employees/AddEmployee';
import background from '../features/login/assets/background.png';
import { ProfilePage } from '../features/profile';
import { DeviceFormPage } from '../features/devices/DeviceFormPage';

export const AppRouter = () => {
  const { user } = useAuth();

  return (
    <StyledContentContainer>
      {user && <SidebarMenu />}
      <StyledRightContainer user={!!user}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/all-devices"
            element={
              <ProtectedRoute>
                <DevicesList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/device-detail/:deviceId"
            element={
              <ProtectedRoute>
                <DeviceDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employees"
            element={
              <ProtectedRoute onlyForAdmin>
                <EmployeeList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-new-employee"
            element={
              <ProtectedRoute onlyForAdmin>
                <AddEmployee />
              </ProtectedRoute>
            }
          />
          <Route
            path="/device-history"
            element={
              <ProtectedRoute onlyForAdmin>
                <DeviceHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-new-device"
            element={
              <ProtectedRoute onlyForAdmin>
                <DeviceFormPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload-excel"
            element={
              <ProtectedRoute onlyForAdmin>
                <ExcelImportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/qr-code-scanner"
            element={
              <ProtectedRoute>
                <QrScanner />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teams"
            element={
              <ProtectedRoute onlyForAdmin>
                <TeamsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/404"
            element={
              <ProtectedRoute>
                <ErrorPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile-page"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to={user ? '/home' : '/login'} />} />
        </Routes>
      </StyledRightContainer>
    </StyledContentContainer>
  );
};

const StyledContentContainer = styled('nav')({
  display: 'flex',
  flexDirection: 'row',

  '@media (max-width: 800px)': {
    flexDirection: 'column',
  },
});

const StyledRightContainer = styled('div')(({ user }) => ({
  flex: '1',
  justifyContent: 'center',
  padding: '40px',
  height: 'calc(100vh - 80px)',
  backgroundImage: !user && `url(${background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
}));
