import { Box, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';

export const Row = ({ label, value }) => (
  <StyledRowBox>
    <StyledLabel>{label}:</StyledLabel>
    <Typography variant="h6">{value}</Typography>
  </StyledRowBox>
);

const StyledRowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  width: '160px',
}));

Row.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
