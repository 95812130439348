import { Box, Container, Typography, styled } from '@mui/material';
import { Button, TextInput } from 'core/ui';
import { useState } from 'react';
import { theme } from 'theme';
import { Comment } from './components/Comment';

export const DeviceComments = ({
  resolvedDeviceComments,
  unresolvedDeviceComments,
  onSaveNewComment,
  onClickResolveComment,
  isAdminOrTemporaryAdmin,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <StyledBox sx={{ marginTop: theme.spacing(5) }}>
      <Typography variant="body2" sx={{ width: '656px', color: theme.palette.grey[700] }}>
        You can add here a device note that you think is important and that Office team should know
        of (i.e. display not working properly, has weak battery etc.)
      </Typography>
      <Container disableGutters>
        <Button
          variant={activeTab === 0 ? 'primary' : 'secondary'}
          label={`Active notes (${unresolvedDeviceComments.length})`}
          onClick={() => handleChange(0)}
        />
        <Button
          variant={activeTab === 1 ? 'primary' : 'secondary'}
          label={`Resolved notes (${resolvedDeviceComments.length})`}
          onClick={() => handleChange(1)}
        />
      </Container>
      {activeTab === 0 ? (
        <StyledBox>
          <TextInput onSave={onSaveNewComment} />
          {unresolvedDeviceComments.map((comment) => (
            <Comment
              isAdminOrTemporaryAdmin={isAdminOrTemporaryAdmin}
              onClick={() => onClickResolveComment(comment.id)}
              comment={comment}
            />
          ))}
        </StyledBox>
      ) : (
        <StyledBox>
          {resolvedDeviceComments.map((comment) => (
            <Comment onClick={() => onClickResolveComment(comment.id)} comment={comment} />
          ))}
        </StyledBox>
      )}
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(5),
  alignSelf: 'stretch',
  margin: 0,
  width: '805px',
}));
