import React from 'react';
import {
  Select,
  Option,
  InputContainer,
  Label,
  ErrorText,
  TooltipText,
  TooltipCard,
  TooltipBox,
} from './styled';

const DropdownInput = ({
  name,
  label,
  errors = {},
  options,
  register = () => {},
  disabledOption = () => {},
  hiddenOption = () => {},
  required = false,
  validationSchema,
}) => (
  <InputContainer>
    {required ? (
      <TooltipCard>
        <TooltipText>{label && <Label>*{label}</Label>}</TooltipText>
        <TooltipBox>
          <p>
            This is <strong>required</strong> field.
          </p>
        </TooltipBox>
      </TooltipCard>
    ) : (
      label && <Label>{label}</Label>
    )}{' '}
    <Select name={name} {...register(name, validationSchema)} style={{ width: '100%' }}>
      <Option disabled value="">
        Select...
      </Option>
      {options.map((option, index) => (
        <Option
          key={index}
          value={option.value}
          hidden={hiddenOption(option.value)}
          disabled={disabledOption(option.value)}
        >
          {option.choice}
        </Option>
      ))}
    </Select>
    {errors[name]?.message && <ErrorText>{errors[name].message}</ErrorText>}
  </InputContainer>
);

export default DropdownInput;
