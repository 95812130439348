import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  baseURL,
  employeeUrl,
  employeeDetailURL,
  allEmployeesByPageURL,
  updateEmployeeInfoURL,
  updateEmployeeLocationURL,
  updateEmployeeTeamURL,
} from '../../SharedAddresses';
import { Api } from '../../api/Api';
import { EmployeeDetailsModal } from './EmployeeDetailsModal';
import CenteredSpinner from '../../components/loaders/Loader';
import TablePagination from '../../components/table/TablePagination';
import { DeviceLibraryTable } from '../../components/table/DeviceLibraryTable';
import { employeeColumns } from '../../components/table/table_data';
import notificationStore from '../../messages/Notifications';
import { PageTitle } from '../../components/PageTitle';
import { AlertMessages } from '../../messages/AlertMessage';

export const EmployeeList = () => {
  const [state, setState] = useState({
    data: [],
    limit: 15,
    numberOfPages: 1,
  });

  const navigate = useNavigate();

  const [activePage, setActivePage] = useState(0);
  const [reload, setReload] = useState(false);
  const [modalLoader, setModalLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [chatState, setChatState] = useState(notificationStore.initialState);

  const [employeeDetail, setEmployeeDetail] = useState({
    id: 0,
    name: null,
    surname: null,
    email: null,
    username: null,
    location: null,
    role: [],
    teamName: null,
    loans: [],
  });

  // //////////////////////////////////////////   rxjs messages

  useLayoutEffect(() => {
    notificationStore.subscribe(setChatState);
    notificationStore.init();
  }, []);

  const handleCloseAfterFiveSeconds = (id) => {
    setTimeout(() => {
      notificationStore.clearMessage(id);
    }, 5000);
  };

  // //////////////// employee details modal

  const handleClose = () => {
    setShow(false);
    setModalLoader(true);
  };
  const handleShow = () => setShow(true);

  const handleClick = (e) => {
    const apiClient = new Api();

    apiClient.get(baseURL + employeeUrl + employeeDetailURL + e.id).then((response) => {
      setEmployeeDetail(response.data);
      setModalLoader(false);
    });
    handleShow();
  };

  // //////////////// get all employees

  useEffect(() => {
    const apiClient = new Api();
    const params = new URLSearchParams();
    params.append('size', String(state.limit));
    params.append('page', activePage);

    apiClient.get(baseURL + employeeUrl + allEmployeesByPageURL, params).then((response) => {
      setState((prev) => ({
        ...prev,
        data: response.data.content,
        numberOfPages: response.data.totalPages,
      }));
    });
  }, [activePage, reload]);

  // /////////////// update employee

  const handleEdit = (data) => {
    const apiClient = new Api();
    setModalLoader(true);
    apiClient
      .put(baseURL + employeeUrl + updateEmployeeInfoURL + employeeDetail.id, {
        name: data.name || null,
        surname: data.surname || null,
        email: null,
      })
      .then(() => {
        const messageObject = {
          title: 'Updated employee details',
          text: [`You have successfully updated ${data.name} ${data.surname}!`],
          id: uuidv4(),
          variant: 'success',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
        setReload(!reload);
        handleClick(employeeDetail);
      })
      .catch((error) => {
        const messageObject = {
          title: 'Unable to update employee details',
          text: [
            `You weren't able to update ${data.name || ''} ${data.surname || ''}!`,
            error.response.data.name,
            error.response.data.surname,
          ],
          id: uuidv4(),
          variant: 'danger',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
        setModalLoader(false);
      });
  };

  const handleEditLocation = (data) => {
    const apiClient = new Api();
    setModalLoader(true);
    const params = new URLSearchParams();
    params.append('new-location', data.location);
    apiClient
      .put(baseURL + employeeUrl + updateEmployeeLocationURL + employeeDetail.id, {}, params)
      .then(() => {
        const messageObject = {
          title: 'Changed employee location',
          text: [
            `You have successfully changed ${employeeDetail.name} ${employeeDetail.surname} location to ${data.location}`,
          ],
          id: uuidv4(),
          variant: 'success',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
        setReload(!reload);
        handleClick(employeeDetail);
      })
      .catch((error) => {
        const messageObject = {
          title: 'Unable to change employee location',
          text: [
            `You weren't able to change ${employeeDetail.name} ${employeeDetail.surname} location to ${data.location}`,
            error.response.data.errors,
          ],
          id: uuidv4(),
          variant: 'danger',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
        setModalLoader(false);
      });
  };

  const handleUpdateEmployeeTeam = (data) => {
    const apiClient = new Api();
    apiClient
      .put(baseURL + employeeUrl + updateEmployeeTeamURL + employeeDetail.id, null, {
        'new-team-id': data.team,
      })
      .then((response) => {
        setEmployeeDetail((prev) => ({ ...prev, teamName: response.data.teamName }));
        const messageObject = {
          title: 'Changed employee team',
          text: [
            `You have successfully changed ${employeeDetail.name} ${employeeDetail.surname} team to ${response.data.teamName}`,
          ],
          id: uuidv4(),
          variant: 'success',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
      })
      .error(() => {
        const messageObject = {
          title: 'Changed employee team',
          text: [`You weren't able to change ${employeeDetail.name} ${employeeDetail.surname} `],
          id: uuidv4(),
          variant: 'danger',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
      });
  };

  const addNewRoleToTheList = (data) => {
    setEmployeeDetail((prev) => ({
      ...prev,
      role: [...prev.role, data],
    }));
  };

  const handleRemovedDevices = () => {
    setEmployeeDetail((prev) => ({
      ...prev,
      loans: [],
    }));
  };

  const removeRoleFromList = (data) => {
    setEmployeeDetail((prev) => ({
      ...prev,
      role: prev.role.filter((role) => role !== data),
    }));
  };

  // ////////////// add new employee

  const handleAddNewEmployee = () => {
    navigate('/add-new-employee');
  };

  if (state.data.length === 0) {
    return <CenteredSpinner />;
  }
  return (
    <div className="position-relative" style={{ paddingBottom: '130px' }}>
      <PageTitle>Employees </PageTitle>
      <button type="button" className="float-end" onClick={handleAddNewEmployee}>
        Add new employee
      </button>
      <DeviceLibraryTable data={state.data} handleClick={handleClick} columns={employeeColumns} />
      <TablePagination data={state} activePage={activePage} setActivePage={setActivePage} />
      {show && (
        <EmployeeDetailsModal
          handleClose={handleClose}
          handleEdit={handleEdit}
          handleEditLocation={handleEditLocation}
          employeeDetail={employeeDetail}
          modalLoader={modalLoader}
          addNewRoleToTheList={addNewRoleToTheList}
          removeRoleFromList={removeRoleFromList}
          handleUpdateEmployeeTeam={handleUpdateEmployeeTeam}
          handleRemovedDevices={handleRemovedDevices}
        />
      )}
      {chatState.data.map((message, index) => (
        <AlertMessages
          key={index}
          message={message}
          closeMessage={notificationStore.clearMessage}
        />
      ))}
    </div>
  );
};
