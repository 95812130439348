import { Icon } from 'core/ui';
import { BorrowedDevicesIcon, PendingRequestIcon } from './assets';

export const LoanVariant = {
  PENDING_REQUEST: {
    label: 'Pending requests',
    iconLabel: Icon.REQUEST_ICON,
    title: 'All clear!',
    message: 'You don’t have any pending requests',
    tableValues: [
      { header: 'Internal ID', value: 'internalId' },
      { header: 'Model', value: 'deviceModel' },
      { header: 'OS Version', value: 'osVersion' },
      { header: 'Platform', value: 'devicePlatform' },
      { header: 'Location', value: 'location' },
      { header: 'Requested by', value: 'borrowerInfo' },
      { header: null, value: 'actionButtons' },
    ],
    icon: <PendingRequestIcon />,
  },
  BORROWED_DEVICES: {
    label: 'Borrowed devices',
    labelIcon: Icon.BORROWED_DEVICES_ICON,
    title: 'Looks like you don’t have any devices',
    message:
      'You don’t have any devices assigned to you. To request device visit all devices page.',
    tableValues: [
      { header: 'Internal ID', value: 'deviceInternalId' },
      { header: 'Device name', value: 'deviceName' },
      { header: 'Model', value: 'deviceModel' },
      { header: 'OS Version', value: 'osVersion' },
      { header: 'Platform', value: 'devicePlatform' },
      { header: 'Loan started', value: 'startLoanDate' },
      { header: 'Loan ending', value: 'expirationDate' },
      // { header: 'Loan ending', value: 'loanDuration' },
      { header: null, value: 'menageButtons' },
    ],
    icon: <BorrowedDevicesIcon />,
  },
};
