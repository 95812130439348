import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  baseURL,
  checkoutURL,
  deviceNotesUrl,
  devicesURL,
  extendLoan,
  getDeviceNotes,
  getQrCode,
  historyOfDevice,
  requestFromAdmin,
  returnURL,
  updateResolveStatusOnNote,
  updateTimestampAdminURL,
} from 'SharedAddresses';
import { Api } from 'api/Api';

export class Queries {
  apiClient = new Api();

  queryClient = useQueryClient();

  getDataDetails = (deviceId) =>
    this.apiClient.get(baseURL + devicesURL + deviceId).then((res) => res.data);

  getQrCode = (deviceId, isAdminOrTemporaryAdmin) =>
    isAdminOrTemporaryAdmin
      ? this.apiClient.get(baseURL + devicesURL + getQrCode + deviceId).then((res) => res.data)
      : null;

  getComments = (deviceId) =>
    this.apiClient.get(baseURL + getDeviceNotes + deviceId).then((res) => res.data);

  getHistory = (deviceId) =>
    this.apiClient.get(baseURL + historyOfDevice + deviceId).then((res) => res.data);

  returnDevice = (deviceId) => this.apiClient.post(baseURL + returnURL + deviceId);

  switchOwner = (data) => this.apiClient.post(baseURL + checkoutURL, data);

  loanDevice = (data) => this.apiClient.post(baseURL + requestFromAdmin, { ...data });

  extendLoan = (data) => this.apiClient.post(baseURL + extendLoan, data);

  updateTimestamp = useMutation({
    mutationKey: ['updateTimestap'],
    mutationFn: (deviceId) =>
      this.apiClient.put(baseURL + devicesURL + updateTimestampAdminURL + deviceId),
    onSuccess: () => {
      this.queryClient.invalidateQueries({ queryKey: ['deviceDetailsQuery'] });
    },
  });

  addNewComment = useMutation({
    mutationKey: ['addDeviceComment'],
    mutationFn: (data) =>
      this.apiClient.post(baseURL + deviceNotesUrl + data.deviceId, null, {
        message: data.message,
      }),
    onSuccess: () => this.queryClient.invalidateQueries({ queryKey: ['deviceCommentsQuery'] }),
  });

  updateResolvedStatusToTrue = useMutation({
    mutationKey: ['updateResolvedStatus'],
    mutationFn: (deviceNoteId) =>
      this.apiClient.put(baseURL + updateResolveStatusOnNote + deviceNoteId, null, {
        'resolved-device-note-status': true,
      }),
    onSuccess: () => {
      this.queryClient.invalidateQueries({ queryKey: ['deviceCommentsQuery'] });
    },
  });
}
