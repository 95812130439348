import { Box, Typography, styled } from '@mui/material';
import { Button } from 'core/ui';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import { PrinterIcon } from './assets';

export const PrintQrCode = ({ qrCode }) => {
  const componentRef = useRef();

  return (
    <StyledBox>
      <div ref={componentRef}>
        <Typography sx={{ paddingLeft: '40px' }} variant="body2">
          Device internal id: {qrCode.deviceInternalId}
        </Typography>
        <Typography sx={{ paddingLeft: '40px' }} variant="body2">
          Device name: {qrCode.deviceName}
        </Typography>
        <img alt="QR code of the device" src={`data:image/png;base64,${qrCode.qrCode}`} />
      </div>
      <ReactToPrint
        trigger={() => <Button label="Print" icon={<PrinterIcon />} />}
        content={() => componentRef.current}
      />
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  paddingTop: theme.spacing(5),
}));
