import {
  BorrowedSearchStatusIcon,
  FreeSearchStatusIcon,
  LegacySearchStatusIcon,
} from 'features/devices/assets';
import {
  CheckCircleIcon,
  CloseIcon,
  Cpu,
  DesktopMobile,
  EditIcon,
  FloppyDiskIcon,
  Mobile,
  PlusCircleIcon,
  SendRight,
  SparkleIcon,
  Tablet,
  TrashIcon,
  UsersGroup,
  WatchSquare,
} from '../assets';
import { SvgIcon } from './SvgIcon';

export const Icon = {
  MOBILE_ICON: <SvgIcon color="grey" isLarge icon={<Mobile />} />,
  TABLET_ICON: <SvgIcon color="grey" isLarge icon={<Tablet />} />,
  WATCH_ICON: <SvgIcon color="grey" isLarge icon={<WatchSquare />} />,
  OTHER_DEVICES_ICON: <SvgIcon color="grey" isLarge icon={<Cpu />} />,
  EMPLOYEES_ICON: <SvgIcon color="grey" icon={<UsersGroup />} />,
  DEVICES_ICON: <SvgIcon color="blue" icon={<DesktopMobile />} />,
  BORROWED_DEVICES_ICON: <SvgIcon color="green" icon={<CheckCircleIcon />} />,
  REQUEST_ICON: <SvgIcon color="yellow" icon={<SparkleIcon />} />,
  CLOSE_ICON: <SvgIcon isLarge icon={<CloseIcon />} />,
  RIGHT_ARROW_ICON: <SvgIcon icon={<SendRight />} />,
  EDIT_ICON: <SvgIcon icon={<EditIcon />} />,
  TRASH_ICON: <SvgIcon icon={<TrashIcon />} />,
  FLOPPY_DISK_ICON: <SvgIcon icon={<FloppyDiskIcon />} />,
  PLUS_CIRCLE_ICON: <SvgIcon isLarge icon={<PlusCircleIcon />} />,
};

export const Status = {
  FREE: { label: 'Available', icon: <FreeSearchStatusIcon /> },
  BORROWED: { label: 'Borrowed', icon: <BorrowedSearchStatusIcon /> },
  IN_A_BOX: { label: 'Legacy device', icon: <LegacySearchStatusIcon /> },
  NOT_WORKING: { label: 'Not working', icon: <LegacySearchStatusIcon /> },
  IN_REPAIR: { label: 'In repair', icon: <LegacySearchStatusIcon /> },
  NOT_FOUND: { label: 'Lost', icon: <LegacySearchStatusIcon /> },
};
