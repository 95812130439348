import React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`;

const CenteredSpinner = () => (
  <LoaderWrapper>
    <ClipLoader />
  </LoaderWrapper>
);

export default CenteredSpinner;
