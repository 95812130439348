import React from 'react';

export const PageTitle = ({ children }) => {
  return (
    <>
      <h1 style={{ textAlign: 'left' }}>{children}</h1>
      <hr />
    </>
  );
};
