import { DevicesIcon, EmployeesIcon, HomeIcon, ProfileIcon, TeamsIcon, UploadIcon } from './assets';

export const SidebarOptions = {
  HOME: {
    icon: <HomeIcon />,
    label: 'Home',
    path: '/home',
    isOnlyForAdmin: false,
  },
  DEVICES: {
    icon: <DevicesIcon />,
    label: 'Devices',
    path: '/all-devices',
    isOnlyForAdmin: false,
  },
  EMPLOYEES: {
    icon: <EmployeesIcon />,
    label: 'Employees',
    path: '/employees',
    isOnlyForAdmin: true,
  },
  TEAMS: {
    icon: <TeamsIcon />,
    label: 'Teams',
    path: '/teams',
    isOnlyForAdmin: true,
  },
  DEVICES_HISTORY: {
    icon: <DevicesIcon />,
    label: 'Devices history',
    path: '/device-history',
    isOnlyForAdmin: true,
  },
  UPLOAD_EXCEL: {
    icon: <UploadIcon />,
    label: 'Upload Excel',
    path: '/upload-excel',
    isOnlyForAdmin: true,
  },
  PROFILE: {
    icon: <ProfileIcon />,
    label: 'Profile',
    path: '/profile-page',
    isOnlyForAdmin: false,
  },
};
