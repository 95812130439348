import { Box, Container, Tab, Tabs, Typography, styled, Grid } from '@mui/material';
import { useAuth } from 'context/auth/AuthProvider';
import { IconHeadline, Icon, Button, RequestedDeviceResponceStatus } from 'core/ui';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Status } from 'core/ui/constants';
import { theme } from 'theme';
import { filter } from 'lodash';
import { useMutation, useQueries } from '@tanstack/react-query';
import { CustomTabPanel, DetailInformation } from './components';
import { DeviceComments } from './DeviceComments';
import { PrintQrCode } from './PrintQrCode';
import { Queries } from './queries';
import { DeviceHistory } from './DeviceHistory';
import TextInput from '../../components/inputs/TextInput';
import { DeviceLibraryDatePicker } from '../../components/inputs/DeviceLibraryDatePicker';
import { ErrorResponseIcon, SuccessResponseIcon } from '../../core/assets';
import CenteredSpinner from '../../components/loaders/Loader';

export const DeviceDetail = () => {
  const TODAY = new Date();
  const navigate = useNavigate();
  const queries = new Queries();
  const { deviceId } = useParams();
  const { userId } = JSON.parse(localStorage.getItem('user'));
  const [extendLoanDate, setExtendLoanDate] = useState(
    new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() + 7),
  );
  const [switchOwnerEmail, setSwitchOwnerEmail] = useState('');
  const [isValidMail, setIsValidMail] = useState(false);
  const [showRequestResponse, setShowRequestResponse] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalTextBody, setModalTextBody] = useState('');
  const { isAdminOrTemporaryAdmin } = useAuth();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const deviceDetailFetchQuery = useQueries({
    queries: [
      {
        queryKey: ['dataDetailsQuery'],
        queryFn: () => queries.getDataDetails(deviceId),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['deviceQrQuery'],
        queryFn: () => queries.getQrCode(deviceId, isAdminOrTemporaryAdmin),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['deviceCommentsQuery'],
        queryFn: () => queries.getComments(deviceId),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['deviceHistoryQuery'],
        queryFn: () => queries.getHistory(deviceId),
        refetchOnWindowFocus: false,
      },
    ],
  });

  const isLoading = deviceDetailFetchQuery.some((it) => it.isFetching);

  const [dataDetails, dataQr, dataComments, dataHistory] = deviceDetailFetchQuery.map(
    (it) => it.data,
  );

  const returnDeviceMutation = useMutation({
    mutationKey: ['returnDevice'],
    mutationFn: (deviceId) => queries.returnDevice(deviceId),
    onSuccess: () => {
      setShowRequestResponse('success');
      setModalTitle('Request for device return has been successful!');
      setModalTextBody('After admin approval device will be available in device library!');
    },
    onError: (error) => {
      setShowRequestResponse('error');
      setModalTitle(error.response.data.errors.join(', '));
    },
  });

  const switchOwnerMutation = useMutation({
    mutationKey: ['switchOwner'],
    mutationFn: (data) => queries.switchOwner(data),
    onSuccess: () => {
      setShowRequestResponse('success');
      setModalTitle('Request for device transfer has been successful!');
      setModalTextBody(
        'You can give device to your college and please remind him to approve that transfer',
      );
    },
    onError: (error) => {
      setShowRequestResponse('error');
      setModalTitle(error.response.data.errors.join(', '));
    },
  });

  const loanDeviceMutation = useMutation({
    mutationKey: ['loanDevice'],
    mutationFn: (data) => queries.loanDevice(data),
    onSuccess: () => {
      setShowRequestResponse('success');
      setModalTitle('Device Requested!');
    },
    onError: (error) => {
      setShowRequestResponse('error');
      setModalTitle(error.response.data.errors.join(', '));
    },
  });

  const extendLoanMutation = useMutation({
    mutationKey: ['extendLoan'],
    mutationFn: (data) => queries.extendLoan(data),
    onSuccess: () => {
      setShowRequestResponse('success');
      setModalTitle('Request for extend of loan date has been successful!');
    },
    onError: (error) => {
      setShowRequestResponse('error');
      setModalTitle(error.response.data.errors.join(', '));
    },
  });

  const onSubmitDeviceAction = () => {
    if (dataDetails.deviceStatus === 'BORROWED') {
      returnDeviceMutation.mutate(deviceId);
    } else {
      loanDeviceMutation.mutate({ deviceId, loanEndDate: extendLoanDate });
    }
  };

  const onSwitchOwner = () => {
    if (isValidEmail(switchOwnerEmail)) {
      switchOwnerMutation.mutate({ deviceId, borrowerEmail: switchOwnerEmail });
    } else {
      setShowRequestResponse('error');
      setModalTitle(`${switchOwnerEmail}is not valid email`);
    }
  };

  const onExtendLoan = () => {
    extendLoanMutation.mutate({ deviceId, loanEndDate: extendLoanDate });
  };

  const onEmailChange = (entryEvent) => {
    setSwitchOwnerEmail(entryEvent.target.value);
    setIsValidMail(isValidEmail(switchOwnerEmail));
  };

  const updateDevice = () => {
    navigate('/add-new-device', { state: { props: dataDetails } });
  };

  if (isLoading) return <CenteredSpinner />;

  return (
    <>
      <StyledMainContainer disableGutters>
        <StyledDeviceNameAndActionBox disableGutters>
          <StyledDeviceNameBox disableGutters>
            <Grid
              container
              disableGutters
              columns={16}
              sx={{ width: '100%' }}
              flexDirection="row"
              alignItems="center"
              display="flex"
              spacing="2"
            >
              <Grid item xs={8} disableGutters>
                <IconHeadline
                  label={dataDetails.model}
                  icon={Icon.MOBILE_ICON}
                  status={Status[dataDetails.deviceStatus].label}
                />
                <StyledTypography>{dataDetails.name}</StyledTypography>
              </Grid>
              <Grid item justifyContent="flex-end" xs={8} disableGutters>
                {dataDetails.deviceStatus === 'BORROWED' &&
                  dataDetails.currentlyHasItId === userId && (
                    <Button
                      style={{ float: 'right' }}
                      variant="primary"
                      label="Return Device"
                      onClick={onSubmitDeviceAction}
                    />
                  )}
                {isAdminOrTemporaryAdmin && (
                  <Button
                    style={{ float: 'right', marginRight: '10px' }}
                    variant="primary"
                    label="Edit device"
                    onClick={updateDevice}
                  />
                )}
              </Grid>
            </Grid>
            {dataDetails.currentlyHasItId === userId && (
              <>
                <Grid
                  container
                  sx={{ width: '100%' }}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  spacing="2"
                >
                  {dataDetails.deviceStatus === 'BORROWED' && (
                    <Grid item display="flex" flexDirection="row" alignItems="center">
                      <Grid item justifyContent="flex-end">
                        <TextInput
                          label="Switch owner"
                          type="email"
                          placeholder="Email"
                          name="owner"
                          onChange={onEmailChange}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="primary"
                          label="Switch Owner"
                          style={{ marginTop: '1rem', marginLeft: '1.5rem' }}
                          onClick={onSwitchOwner}
                          isDisabled={!isValidMail}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <Grid item>
                    <DeviceLibraryDatePicker
                      minDate={TODAY}
                      style={{ padding: '0.6rem', marginTop: '1rem' }}
                      label={
                        dataDetails.deviceStatus === 'BORROWED' ? 'Extend Loan' : 'Choose Loan Date'
                      }
                      maxDate={
                        new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() + 60)
                      }
                      value={extendLoanDate}
                      onChange={setExtendLoanDate}
                    />
                    {dataDetails.deviceStatus === 'BORROWED' && (
                      <Button
                        style={{ marginLeft: '0.8rem' }}
                        isDisabled={extendLoanDate === null}
                        variant="primary"
                        label="Extend Loan"
                        onClick={onExtendLoan}
                      />
                    )}
                    {dataDetails.deviceStatus === 'FREE' && (
                      <Button
                        style={{ marginLeft: '0.8rem' }}
                        isDisabled={extendLoanDate === null}
                        variant="primary"
                        label="Request Device"
                        onClick={onSubmitDeviceAction}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </StyledDeviceNameBox>
        </StyledDeviceNameAndActionBox>
        <Box sx={{ width: '100%', padding: 0 }}>
          <Box sx={{ borderBottom: 1, borderColor: theme.palette.grey[500], width: 'fit-content' }}>
            <Tabs value={tabValue} onChange={handleChange}>
              <StyledLabelTab label="Details" />
              <StyledLabelTab label="Device notes" />
              <StyledLabelTab label="Device History" />
              {isAdminOrTemporaryAdmin && <StyledLabelTab label="QR Code" />}
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <DetailInformation
              deviceInfo={dataDetails}
              isAdminOrTemporaryAdmin={isAdminOrTemporaryAdmin}
              onClickUpdateTimestampButton={() => queries.updateTimestamp.mutate(deviceId)}
              onClickEditDetailsButton={() => {}}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <DeviceComments
              isAdminOrTemporaryAdmin={isAdminOrTemporaryAdmin}
              onSaveNewComment={(message) => queries.addNewComment.mutate({ deviceId, message })}
              onClickResolveComment={(deviceNoteId) =>
                queries.updateResolvedStatusToTrue.mutate(deviceNoteId)
              }
              resolvedDeviceComments={filter(dataComments, { resolved: true })}
              unresolvedDeviceComments={filter(dataComments, { resolved: false })}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <DeviceHistory deviceHistoryData={dataHistory} />
          </CustomTabPanel>
          {isAdminOrTemporaryAdmin && (
            <CustomTabPanel value={tabValue} index={3}>
              <PrintQrCode qrCode={dataQr} />
            </CustomTabPanel>
          )}
        </Box>
      </StyledMainContainer>
      <RequestedDeviceResponceStatus
        open={!!showRequestResponse}
        variant={showRequestResponse}
        deviceModel={dataDetails?.model}
        deviceOsVersion={dataDetails?.osVersion}
        deviceName={dataDetails?.name}
        devicePlatform={dataDetails?.devicePlatform}
        icon={showRequestResponse === 'success' ? <SuccessResponseIcon /> : <ErrorResponseIcon />}
        title={modalTitle}
        textBody={modalTextBody}
        headlineIcon={Icon.MOBILE_ICON}
        handleClose={() => setShowRequestResponse(null)}
      />
    </>
  );
};

const StyledLabelTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.grey[600],
  textTransform: 'none',
}));

const StyledMainContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  gap: theme.spacing(5),
  alignSelf: 'stretch',
  borderRadius: '8px',
  background: theme.palette.common.white,
  boxShadow: '3px 4px 16px 0px rgba(0, 0, 0, 0.09)',
  width: '100%',
}));

const StyledDeviceNameAndActionBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  width: '100%',
});

const StyledDeviceNameBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.grey[600],
  fontWeight: 500,
}));
