import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IconButton, ThemeProvider } from '@mui/material';
import { SnackbarProvider, closeSnackbar as handleClick } from 'notistack';
import { Icon, StyledMaterialDesignContent } from 'core/ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from './context/auth/AuthProvider';
import { AppRouter } from './routing';
import { theme } from './theme';

function App() {
  const queryClient = new QueryClient();

  return (
    <Router>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              hideIconVariant
              maxSnack={4}
              autoHideDuration={5000}
              Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              action={(snackbarId) => (
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => handleClick(snackbarId)}
                >
                  {Icon.CLOSE_ICON}
                </IconButton>
              )}
            >
              <AppRouter />
              <ReactQueryDevtools initialIsOpen={false} />
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
