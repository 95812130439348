import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { Api } from '../../api/Api';
import { baseURL, createNewEmployeeURL } from '../../SharedAddresses';
import TextInput from '../../components/inputs/TextInput';
import DropdownInput from '../../components/inputs/DropdownInput';
import { Form } from '../../components/forms/styled';
import { dropdownOptions } from '../../input_data';
import CenteredSpinner from '../../components/loaders/Loader';
import { Row } from '../list-of-devices/styled';
import notificationStore from '../../messages/Notifications';
import { AlertMessages } from '../../messages/AlertMessage';

export default function AddEmployee() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [chatState, setChatState] = useState(notificationStore.initialState);

  const employeeFormDefaultValues = {
    name: '',
    surname: '',
    location: 'SPLIT',
    email: '',
    passwword: 'password',
  };

  // //////////////////////////////////////////   rxjs messages

  useLayoutEffect(() => {
    notificationStore.subscribe(setChatState);
    notificationStore.init();
  }, []);

  const handleCloseAfterFiveSeconds = (id) => {
    setTimeout(() => {
      notificationStore.clearMessage(id);
    }, 5000);
  };

  // //////////////////// create new employee

  const handleSubmit = (data) => {
    const apiClient = new Api();
    setIsLoading(true);
    apiClient
      .post(baseURL + createNewEmployeeURL, { ...data })
      .then(() => {
        const messageObject = {
          title: 'Creating new employee',
          text: [`You have successfully created new employee ${data.name} ${data.surname}`],
          id: uuidv4(),
          variant: 'success',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
        navigate('/employees');
      })
      .catch((err) => {
        const messageObject = {
          title: 'Creating new employee',
          text: [
            `You weren't able to created new employee ${data.name} ${data.surname}`,
            ...err.response.data.errors,
          ],
          id: uuidv4(),
          variant: 'danger',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
        navigate('/employees');
      });
  };

  const {
    handleSubmit: submitForm,
    register,
    formState: { errors },
  } = useForm({ defaultValues: employeeFormDefaultValues });

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <Form onSubmit={submitForm(handleSubmit)}>
        <Row>
          <TextInput
            register={register}
            type="text"
            placeholder="Name"
            name="name"
            label="Name"
            validationSchema={{ required: 'This field is required' }}
            errors={errors}
          />
          <TextInput
            register={register}
            type="text"
            placeholder="Surname"
            name="surname"
            label="Surname"
          />
        </Row>
        <DropdownInput
          register={register}
          label="Location"
          options={dropdownOptions.location}
          name="location"
        />
        <TextInput
          register={register}
          type="email"
          placeholder="Email"
          name="email"
          validationSchema={{ required: 'This field is required' }}
          label="Email"
          errors={errors}
        />
        <TextInput
          register={register}
          type="text"
          placeholder="Password"
          name="password"
          validationSchema={{ required: 'This field is required' }}
          label="Password"
          errors={errors}
        />

        <button type="submit">Add new employee</button>
      </Form>
      {chatState.data.map((message, index) => (
        <AlertMessages key={index} message={message} closeMessage={notificationStore.clearChat} />
      ))}
    </>
  );
}
