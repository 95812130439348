import React, { useEffect, useLayoutEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Api } from '../../api/Api';
import {
  addNewRoleToEmployeeURL,
  addNewTemporearyAdminRoleToEmployeeURL,
  baseURL,
  clearAllLoansURL,
  employeeUrl,
  getAllTeamsURL,
  removeRoleFromEmployeeURL,
} from '../../SharedAddresses';
import FormContainer from '../../components/forms/FormContainer';
import TextInput from '../../components/inputs/TextInput';
import DropdownInput from '../../components/inputs/DropdownInput';
import { dropdownOptions } from '../../input_data';
import { CalendarContainer } from './styled';
import CenteredSpinner from '../../components/loaders/Loader';
import { useAuth } from '../../context/auth/AuthProvider';
import { Form } from '../../components/forms/styled';
import notificationStore from '../../messages/Notifications';
import {
  ButtonGroup,
  Modal,
  ModalBanner,
  ModalContent,
  ModalFooter,
  ModalShadow,
  Tab,
} from '../../components/modals/styled';

const LOCATIONS = [
  { choice: 'Zagreb', value: 'ZAGREB' },
  { choice: 'Split', value: 'SPLIT' },
  { choice: 'Osijek', value: 'OSIJEK' },
  { choice: 'Rijeka', value: 'RIJEKA' },
  { choice: 'Homebased', value: 'HOMEBASED' },
];

export const EmployeeDetailsModal = ({
  handleClose,
  handleEdit,
  handleEditLocation,
  employeeDetail,
  modalLoader,
  addNewRoleToTheList,
  removeRoleFromList,
  handleUpdateEmployeeTeam,
  handleRemovedDevices,
}) => {
  const [state, setState] = useState({
    apiResponseSuccessForDeleteAllLoans: false,
    openAlert: false,
    showCalendar: false,
    dateUntil: new Date(),
    teams: [{ choice: 'Remove team', value: '-1' }],
    errorMessages: [],
  });
  const { isTemporaryAdmin } = useAuth();
  const { handleSubmit, register, reset } = useForm({ team: '' });
  const [setChatState] = useState(notificationStore.initialState);
  const chosenTab = ['About', 'Edit employee', 'Employees devices'];
  const [active, setActive] = useState(chosenTab[0]);

  // //////////////////////////////////////////   rxjs messages

  useLayoutEffect(() => {
    notificationStore.subscribe(setChatState);
    notificationStore.init();
  }, []);

  const handleCloseAfterFiveSeconds = (id) => {
    setTimeout(() => {
      notificationStore.clearMessage(id);
    }, 5000);
  };

  //  ////////////////////////  get all teams for update employee team
  useEffect(() => {
    const apiClient = new Api();
    apiClient.get(baseURL + getAllTeamsURL).then((response) => {
      if (state.teams.length === 1) {
        response.data?.map((team) =>
          setState((prev) => ({
            ...prev,
            teams: [...prev.teams, { choice: team.name, value: team.id }],
          })),
        );
      }
    });
  }, []);

  useEffect(() => {
    const filteredTeam = state.teams.find((team) => team.choice === employeeDetail.teamName);
    reset({
      team: filteredTeam?.value || '',
    });
  }, [employeeDetail]);

  // ///////////////////// loans

  const handleRemoveLoansFromEmployee = () => {
    const apiClient = new Api();
    apiClient
      .delete(baseURL + employeeUrl + clearAllLoansURL + employeeDetail.id)
      .then(() => {
        const messageObject = {
          title: 'Deleted all loans from employee',
          text: [],
          id: uuidv4(),
          variant: 'success',
        };

        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
        handleRemovedDevices();
      })
      .catch(() => {
        const messageObject = {
          title: 'Something went wrong!',
          text: [],
          id: uuidv4(),
          variant: 'danger',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
      });
  };

  // ////////////////////////  roles

  const alreadyHasRole = (data) => employeeDetail.role?.includes(data);
  const hiddenOption = (data) =>
    (data === 'ADMIN' || data === 'TEMPORARY_ADMIN') && isTemporaryAdmin;

  const addNewRole = (data) => {
    if (data.newRole === 'TEMPORARY_ADMIN') {
      setState((prev) => ({
        ...prev,
        showCalendar: true,
      }));
    } else {
      const apiClient = new Api();
      apiClient
        .put(baseURL + employeeUrl + addNewRoleToEmployeeURL + employeeDetail.id, null, {
          'new-role': data.newRole,
        })
        .then(() => addNewRoleToTheList(data.newRole));
    }
  };

  const addNewTemporaryAdmin = (data) => {
    setState((prev) => ({ ...prev, showCalendar: false }));
    const apiClient = new Api();
    apiClient
      .put(
        baseURL + employeeUrl + addNewTemporearyAdminRoleToEmployeeURL + employeeDetail.id,
        null,
        {
          'date-role-ends': moment(data).format('YYYY-MM-DD'),
        },
      )
      .then(() => {
        addNewRoleToTheList('TEMPORARY_ADMIN');
      });
  };

  const handleRemovingRoleFromEmployee = (role) => {
    const apiClient = new Api();
    apiClient
      .put(baseURL + employeeUrl + removeRoleFromEmployeeURL + employeeDetail.id, null, {
        'remove-role': role,
      })
      .then(() => removeRoleFromList(role))
      .catch((error) => {
        const messageObject = {
          title: 'Removing role',
          text: error.response.data.errors,
          id: uuidv4(),
          variant: 'danger',
        };
        notificationStore.createNewNotification(messageObject);
        handleCloseAfterFiveSeconds(messageObject.id);
      });
  };

  return (
    <div>
      <ModalShadow
        onClick={() => {
          setState((prev) => ({ ...prev, showCalendar: false }));
          handleClose();
        }}
      />
      <Modal>
        {modalLoader ? (
          <CenteredSpinner />
        ) : (
          <>
            <ModalBanner>
              <h1>
                {employeeDetail.name} {employeeDetail.surname}
              </h1>
              <h3 style={{ paddingLeft: '10px' }}>{employeeDetail.email}</h3>
            </ModalBanner>
            <ModalContent>
              <ButtonGroup>
                {chosenTab.map((type) => (
                  <Tab key={type} active={active === type} onClick={() => setActive(type)}>
                    {type}
                  </Tab>
                ))}
              </ButtonGroup>
              {
                {
                  About: (
                    <>
                      <h6>
                        <strong> Current team:</strong> {employeeDetail.teamName || 'no team'}
                        <hr />
                      </h6>
                      <h6>
                        <strong>Employee roles: </strong>
                      </h6>
                      {employeeDetail.role?.map((role, index) => (
                        <div key={index}>
                          {role}
                          <button
                            type="button"
                            onClick={() => handleRemovingRoleFromEmployee(role)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <hr />
                      <FormContainer
                        onSubmit={addNewRole}
                        defaultValues={{ newRole: '', dateUntil: '' }}
                      >
                        <DropdownInput
                          label="Add new role"
                          options={dropdownOptions.role}
                          name="newRole"
                          disabledOption={alreadyHasRole}
                          hiddenOption={hiddenOption}
                        />
                        <button type="submit">Add new role</button>
                      </FormContainer>
                      {state.showCalendar && (
                        <CalendarContainer>
                          <h6> Temporary admin untill date: </h6>
                          <ReactDatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={state.dateUntil}
                            onChange={addNewTemporaryAdmin}
                          />
                        </CalendarContainer>
                      )}
                    </>
                  ),
                  'Edit employee': (
                    <>
                      <FormContainer
                        onSubmit={handleEdit}
                        defaultValues={{
                          name: employeeDetail.name,
                          surname: employeeDetail.surname,
                          email: employeeDetail.email,
                        }}
                      >
                        <TextInput label="Name" name="name" type="text" placeholder="Name" />
                        <TextInput
                          label="Surname"
                          name="surname"
                          type="text"
                          placeholder="Surname"
                        />
                        {/* <TextInput label="Email" name="email" type="text" placeholder="Email" /> */}
                        <button type="submit">Save changes</button>
                      </FormContainer>

                      <FormContainer
                        onSubmit={handleEditLocation}
                        defaultValues={{
                          location: employeeDetail.location,
                        }}
                      >
                        <DropdownInput label="Location" options={LOCATIONS} name="location" />
                        <button type="submit">Save changes</button>
                      </FormContainer>

                      <Form onSubmit={handleSubmit(handleUpdateEmployeeTeam)}>
                        <DropdownInput
                          register={register}
                          label="Team"
                          options={state.teams}
                          name="team"
                        />
                        <button type="submit">Save changes</button>
                      </Form>
                    </>
                  ),
                  'Employees devices':
                    (employeeDetail.loans || []).length === 0 ? (
                      <div> Employee doesn't have any devices! </div>
                    ) : (
                      <div>
                        {employeeDetail.loans.map((loan, index) => (
                          <div key={index} style={{ padding: '2px' }}>
                            <h6> {index + 1} </h6>
                            <h6>Device name: {loan.deviceName}</h6>
                            <h6>Device model: {loan.deviceModel} </h6>
                            <h6>
                              Borrowed on: {loan.timeStartLoan[2]}.{loan.timeStartLoan[1]}.
                              {loan.timeStartLoan[0]}
                            </h6>
                          </div>
                        ))}
                        <button type="button" onClick={handleRemoveLoansFromEmployee}>
                          Remove all loans from employee!
                        </button>
                      </div>
                    ),
                }[active]
              }
            </ModalContent>
            <ModalFooter>
              <button type="button" onClick={handleClose}>
                Close
              </button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </div>
  );
};
