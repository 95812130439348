import { Box, Typography, styled } from '@mui/material';
import { Button } from 'core/ui';
import { LoadingIcon } from '../assets';
import { PlusCircleIcon } from '../../../core/assets';
import { formatDateToString } from '../../../core/util/dateFormatUtil';

export const DetailInformation = ({
  onClickUpdateTimestampButton,
  onClickEditDetailsButton,
  deviceInfo,
  isAdminOrTemporaryAdmin,
}) => (
  <StyledDetailInformationBox>
    <StyledTextBox>
      <StyledTitle>Current owner</StyledTitle>
      <Box>
        {deviceInfo.deviceStatus === 'BORROWED' && (
          <>
            <Box sx={{ display: 'flex' }}>
              <StyledText>Name: </StyledText>
              <StyledText isThin>
                {deviceInfo.currentlyHasItName} {deviceInfo.currentlyHasItSurname}
              </StyledText>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <StyledText>Email: </StyledText>
              <StyledText isThin> {deviceInfo.currentlyHasItEmail}</StyledText>
            </Box>
          </>
        )}
        <Box sx={{ display: 'flex' }}>
          <StyledText>Current location: </StyledText>
          <StyledText isThin> {deviceInfo.currentLocation}</StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>Permanent location: </StyledText>
          <StyledText isThin> {deviceInfo.permanentLocation}</StyledText>
        </Box>
      </Box>
    </StyledTextBox>
    <StyledTextBox>
      <StyledTitle>About</StyledTitle>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>OS version: </StyledText>
          <StyledText isThin> {deviceInfo.osVersion}</StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>Size: </StyledText>
          <StyledText isThin>
            {deviceInfo.size} {deviceInfo.sizeMeasuringUnit}
          </StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>Device platform: </StyledText>
          <StyledText isThin>{deviceInfo.devicePlatform}</StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>Device type: </StyledText>
          <StyledText isThin>{deviceInfo.deviceType}</StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>Last time admin had device: </StyledText>
          <StyledText isThin>
            {/* TODO: this needs to be changed with BE refactor */}
            {formatDateToString(deviceInfo.updateTimestamp)}
          </StyledText>
        </Box>
      </Box>
    </StyledTextBox>
    <StyledTextBox>
      <StyledTitle>Specification</StyledTitle>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>Internal Id: </StyledText>
          <StyledText isThin> {deviceInfo.internalId}</StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>Serial number: </StyledText>
          <StyledText isThin>{deviceInfo.serialNumber || ' NOT DEFINED'}</StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>UDID: </StyledText>
          <StyledText isThin>{deviceInfo.udid || ' NOT DEFINED'}</StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>IMEI: </StyledText>
          <StyledText isThin>{deviceInfo.imei || ' NOT DEFINED'}</StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>Team owner: </StyledText>
          <StyledText isThin>{deviceInfo.teamName || ' NOT PART OF THE TEAM'}</StyledText>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledText>Find my phone: </StyledText>
          <StyledText isThin>{deviceInfo.findMyPhone || ' NOT DEFINED'}</StyledText>
        </Box>
      </Box>
    </StyledTextBox>
    {isAdminOrTemporaryAdmin && (
      <StyledButtonBox>
        <Button
          label="Edit details"
          variant="secondary"
          icon={<PlusCircleIcon />}
          onClick={onClickEditDetailsButton}
        />
        <Button
          label="Update timestamp"
          variant="secondary"
          icon={<LoadingIcon />}
          onClick={onClickUpdateTimestampButton}
        />
      </StyledButtonBox>
    )}
  </StyledDetailInformationBox>
);

const StyledDetailInformationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(5),
  alignSelf: 'stretch',
  padding: 0,
  marginTop: theme.spacing(5),
}));

const StyledButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const StyledTextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  padding: 0,
  whiteSpace: 'pre',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.common.black,
}));

const StyledText = styled(Typography)(({ theme, isThin }) => ({
  ...theme.typography.body2,
  color: theme.palette.grey[700],
  fontWeight: isThin ? 500 : 700,
}));
