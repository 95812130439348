import React from 'react';
import { CircularProgress, Container, styled } from '@mui/material';

export const Loading = () => (
  <StyledLoadingContainer>
    <CircularProgress size="24px" color="primary" />
  </StyledLoadingContainer>
);

const StyledLoadingContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});
