import { Stack, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { StatusChip } from './StatusChip';

export const IconHeadline = ({ icon, label, status }) => (
  <Stack direction="row" alignItems="center" gap={1}>
    {icon}
    <StyledHeader variant="h5">{label}</StyledHeader>
    {status && <StatusChip status={status} />}
  </Stack>
);

IconHeadline.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

const StyledHeader = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.common.black,
}));
