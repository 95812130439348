import React from 'react';
import styled from 'styled-components';

const AlertStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  text-align: start;
  max-width: 500px;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  border-radius: 3px;
  z-index: 999999;
  background: ${(props) => (props.isSucess ? '#9CCC65' : '#ED1807')};
`;

export const AlertMessages = ({ message, closeMessage }) => {
  return (
    <AlertStyled isSucess={message.variant === 'success'}>
      <div>
        {message.title} <hr />
        {message.text?.map((messageText, index) => (
          <p key={index}>{messageText}</p>
        ))}
      </div>
      <button type="button" onClick={() => closeMessage(message.id)}>
        {' '}
        Close{' '}
      </button>
    </AlertStyled>
  );
};
