import { Dropdown, Icon } from 'core/ui';
import { useState } from 'react';
import { LocationDropdown, SearchAndFiltersContainer, SearchField } from 'core/components';
import { useNavigate } from 'react-router-dom';
import { Statuses } from './consts';
import { useAuth } from '../../context/auth/AuthProvider';

export const DevicesFiltering = ({ model, setModel, location, setLocation, status, setStatus }) => {
  const [showCancelIcon, setShowCancelIcon] = useState(false);
  const { isAdminOrTemporaryAdmin } = useAuth();
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setModel(e.target.value);
    setShowCancelIcon(Boolean(e.target.value.length > 0));
  };

  const handleCancelClick = () => {
    setModel('');
    setShowCancelIcon(false);
  };

  const handleStatusChange = (status) => {
    setStatus(status);
  };

  const handleLocationChange = (location) => {
    setLocation(location);
  };

  const navigateToAddDeviece = () => {
    navigate('/add-new-device');
  };

  return (
    <SearchAndFiltersContainer
      title="Devices"
      titleIcon={Icon.DEVICES_ICON}
      buttonLabel="Add device"
      displayButton={isAdminOrTemporaryAdmin}
      onClick={navigateToAddDeviece}
    >
      <SearchField
        onChange={handleSearchChange}
        value={model}
        showCancelIcon={showCancelIcon}
        onCancel={handleCancelClick}
      />
      <LocationDropdown value={location} onChange={handleLocationChange} displayEmpty />
      <Dropdown
        value={status}
        onChange={handleStatusChange}
        options={Statuses}
        label="Status"
        defaultValueLabel="All statuses"
        displayEmpty
      />
    </SearchAndFiltersContainer>
  );
};
