// TODO girls didn't define what will happen when we delete team, so that button doesn't do anything
import { FormControl, InputAdornment, OutlinedInput, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Button } from './Button';
import { Icon } from './constants';

export const EditableTitle = ({ defaultValue, onSave, disabled, setActive, id }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [formInput, setFormInput] = useState(defaultValue);
  const inputRef = useRef(null);

  const handleOnChange = (event) => {
    setFormInput(event.target.value);
  };

  const handleCancel = () => {
    setFormInput(defaultValue);
    setIsReadOnly(true);
    setActive(null);
  };

  const handleSave = () => {
    onSave({ formInput, id });
    setIsReadOnly(true);
    setShowButtons(false);
  };

  const handleEditClick = () => {
    inputRef.current.focus();
    setIsReadOnly(false);
    setShowButtons(true);
    setActive(id);
  };

  const handleOnMouseEnter = () => {
    if (isReadOnly) {
      setShowButtons(!disabled && true);
    }
  };

  const handleOnMouseLeave = () => {
    if (isReadOnly) {
      setShowButtons(false);
    }
  };

  return (
    <StyledFormControl disabled={disabled}>
      <StyledOutlinedInput
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onChange={handleOnChange}
        value={formInput}
        readOnly={isReadOnly}
        inputRef={inputRef}
        endAdornment={
          showButtons && (
            <InputAdornment position="end">
              {isReadOnly ? (
                <StyledDiv>
                  {/* <Button label="Delete" icon={Icon.TRASH_ICON} /> */}
                  <Button onClick={handleEditClick} label="Edit" icon={Icon.EDIT_ICON} />
                </StyledDiv>
              ) : (
                <StyledDiv>
                  <Button label="Cancel" variant="secondary" onClick={handleCancel} />
                  <Button onClick={handleSave} label="Save" icon={Icon.FLOPPY_DISK_ICON} />
                </StyledDiv>
              )}
            </InputAdornment>
          )
        }
      />
    </StyledFormControl>
  );
};

EditableTitle.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

EditableTitle.defaultProps = {
  disabled: false,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  m: 1,
  width: '100%',
  height: '72px',
  '.Mui-focused': {
    border: `3px solid ${theme.palette.action.focus}`,
  },
}));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.primary.main,
}));

const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
});
