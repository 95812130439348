import {
  CheckboxContainer,
  LabelText,
  CheckboxContent,
  CheckBoxInput,
  CheckboxText,
} from './styled';

const Checkbox = ({ label, name, checkboxLabel, register = () => {} }) => {
  return (
    <CheckboxContainer>
      <LabelText>{label}</LabelText>
      <CheckboxContent>
        <CheckBoxInput name={name} {...register(name)} type="checkbox" />
        <CheckboxText>{checkboxLabel}</CheckboxText>
      </CheckboxContent>
    </CheckboxContainer>
  );
};

export default Checkbox;
