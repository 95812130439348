import { Container, Typography, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { baseURL, devicesURL, getDevicesByPageURL } from 'SharedAddresses';
import { Api } from 'api/Api';
import { useState } from 'react';
import { DevicesFiltering } from './DevicesFiltering';
import { DevicesTable } from './DevicesTable';
import { NoDevicesFoundIcon } from './assets';
import { useDebounce } from './hooks/useDebounce';

export const DevicesList = () => {
  const apiClient = new Api();
  const [searchLocation, setSearchLocation] = useState(null);
  const [searchStatus, setSearchStatus] = useState(null);
  const [searchModel, setSearchModel] = useState(null);

  const params = new URLSearchParams();
  params.append('size', '20');
  if (searchLocation) params.append('location', searchLocation);
  if (searchStatus) params.append('status', searchStatus);
  if (searchModel) params.append('model', searchModel);

  const debouncedSearchModel = useDebounce(searchModel, 500);

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ['allDevices', searchLocation, debouncedSearchModel, searchStatus],
    queryFn: () =>
      apiClient.get(baseURL + devicesURL + getDevicesByPageURL, params).then((res) => res.data),
  });

  if (error) return `An error has occurred: ${error.message}`;

  return (
    <StyledContainer disableGutters maxWidth={false}>
      <DevicesFiltering
        location={searchLocation}
        setLocation={setSearchLocation}
        model={searchModel}
        setModel={setSearchModel}
        status={searchStatus}
        setStatus={setSearchStatus}
      />
      {!isFetching && data?.content.length === 0 ? (
        <StyledNoDevicesFoundContainer disableGutters>
          <NoDevicesFoundIcon />
          <StyledText>
            <Typography variant="h5">Oops! No Results Found</Typography>
            <StyledTypography>
              Please try a different search term or check for any filters applied to refine your
              search.
            </StyledTypography>
          </StyledText>
        </StyledNoDevicesFoundContainer>
      ) : (
        <DevicesTable isLoading={isLoading} devices={data?.content} />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(5),
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  flex: '1 0 0',
  maxWidth: '1250px',
}));

const StyledNoDevicesFoundContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(4),
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
  borderRadius: '8px',
  background: theme.palette.common.white,
  boxShadow: '3px 4px 16px 0px rgba(0, 0, 0, 0.09)',
}));

const StyledText = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  width: '366px',
  color: theme.palette.grey[600],
  fontWeight: 500,
}));
