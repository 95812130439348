import { Box, Paper as MuiPaper, Typography, styled } from '@mui/material';

export const Paper = ({ children, icon, title, rightButtons }) => (
  <StyledMuiPaper>
    {title && (
      <StyledBox>
        <StyledTitleBox>
          {icon}
          <Typography variant="h5">{title}</Typography>
        </StyledTitleBox>
        {rightButtons}
      </StyledBox>
    )}
    {children}
  </StyledMuiPaper>
);

const StyledMuiPaper = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(4),
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(5),
  alignSelf: 'stretch',
  borderRadius: '8px',
  boxShadow: '3px 4px 16px 0px rgba(0, 0, 0, 0.09)',
  maxWidth: '1200px',
}));

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledTitleBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
});
