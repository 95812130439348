import { CircularProgress } from '@mui/material';
import { LocationIcon } from '../assets';
import { Locations } from '../consts';
import { Dropdown } from '../ui';

export const LocationDropdown = ({ value, onChange, isLoading, displayEmpty = false }) => (
  <Dropdown
    value={value}
    startIcon={isLoading ? <CircularProgress /> : <LocationIcon />}
    onChange={onChange}
    options={Locations}
    label="Location"
    defaultValueLabel={displayEmpty ? 'All locations' : ''}
    disabled={isLoading}
    displayEmpty={displayEmpty}
  />
);
