import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Container, Grid, styled, Typography } from '@mui/material';
import { Api } from '../../api/Api';
import {
  baseURL,
  createNewDeviceURL,
  devicesURL,
  updateDeviceByAdminURL,
} from '../../SharedAddresses';
import { Form } from '../../components/forms/styled';
import TextInput from '../../components/inputs/TextInput';
import DropdownInput from '../../components/inputs/DropdownInput';
import { dropdownOptions } from '../../input_data';
import MeasurementInput from '../../components/inputs/MeasurementInput';
import Checkbox from '../../components/inputs/Checkbox';
import { Button, Icon, IconHeadline, RequestedDeviceResponceStatus } from '../../core/ui';
import { Status } from '../../core/ui/constants';
import { ErrorResponseIcon, SuccessResponseIcon } from '../../core/assets';

export const DeviceFormPage = () => {
  const location = useLocation();
  const deviceDetails = location.state?.props;
  const navigate = useNavigate();
  const [showRequestResponse, setShowRequestResponse] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalTextBody, setModalTextBody] = useState('');

  const hasState = !!deviceDetails;

  const [deviceInput, setDeviceInput] = useState({
    model: hasState ? deviceDetails.model : '',
    name: hasState ? deviceDetails.name : '',
    ...(hasState
      ? {
          currentLocation: hasState ? deviceDetails.currentLocation : '',
          permanentLocation: hasState ? deviceDetails.permanentLocation : '',
        }
      : { location: '' }),
    deviceStatus: hasState ? deviceDetails.deviceStatus : '',
    deviceType: hasState ? deviceDetails.deviceType : '',
    devicePlatform: hasState ? deviceDetails.devicePlatform : '',
    size: hasState ? deviceDetails.size : '',
    osVersion: hasState ? deviceDetails.osVersion : '',
    internalId: hasState ? deviceDetails.internalId : '',
    sizeMeasuringUnit: hasState ? deviceDetails.sizeMeasuringUnit : '',
    findMyPhone: hasState ? deviceDetails.findMyPhone : false,
    link: hasState ? deviceDetails.link : null,
    udid: hasState ? deviceDetails.udid : null,
    imei: hasState ? deviceDetails.imei : null,
    serialNumber: hasState ? deviceDetails.serialNumber : null,
  });

  const setModalResponseData = (title, body, variant) => {
    setModalTitle(title);
    setModalTextBody(body);
    setShowRequestResponse(variant);
  };

  const handleSubmit = (data) => {
    const apiClient = new Api();

    apiClient
      .post(baseURL + devicesURL + createNewDeviceURL, { ...data })
      .then(() => {
        setDeviceInput(data);
        setModalResponseData(
          'Successfully created new device',
          `You successfully created ${data.model}`,
          'success',
        );
      })
      .catch((errorApiCall) => {
        setModalResponseData(
          'Unable to create new device',
          errorApiCall.response.data.errors,
          'danger',
        );
      });
  };

  const handleUpdate = (data) => {
    const apiClient = new Api();

    apiClient
      .put(baseURL + devicesURL + updateDeviceByAdminURL + deviceDetails.id, {
        ...data,
      })
      .then(() => {
        setDeviceInput(data);
        setModalResponseData(
          'Sucessfull update device info',
          `You successfully updated ${data.model} information`,
          'success',
        );
      })
      .catch((errorApiCall) => {
        setModalResponseData('Unable to update device info', errorApiCall.response.data, 'danger');
      });
  };

  const handleModalClose = () => {
    if (showRequestResponse === 'success') {
      navigate('/all-devices');
    }
    setShowRequestResponse(null);
  };

  const {
    handleSubmit: submitForm,
    register,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: deviceInput });

  const onSubmit = (data) => {
    if (!hasState) {
      handleSubmit(data);
    } else {
      handleUpdate(data);
    }
  };

  const onCancel = () => {
    navigate(hasState ? `/device-detail/${deviceDetails.id}` : '/all-devices');
  };

  return (
    <StyledMainContainer disableGutters>
      <Form onSubmit={submitForm(onSubmit)}>
        {!hasState ? (
          <Typography variant="h4">Add new device</Typography>
        ) : (
          <>
            <Typography variant="h4">Edit device</Typography>
            <Typography marginBottom="50px" variant="body1">
              Update device information
            </Typography>
          </>
        )}
        {hasState && (
          <StyledDeviceNameBox disableGutters>
            <GridBordered
              container
              disableGutters
              columns={16}
              sx={{ width: '100%' }}
              flexDirection="row"
              alignItems="center"
              display="flex"
              spacing="2"
            >
              <Grid item xs={8} disableGutters margin="10px">
                <IconHeadline
                  label={deviceDetails.model}
                  icon={Icon.MOBILE_ICON}
                  status={Status[deviceDetails.deviceStatus].label}
                />
                <Typography>{deviceDetails.name}</Typography>
              </Grid>
            </GridBordered>
          </StyledDeviceNameBox>
        )}
        <Typography variant="h6" marginBottom="15px">
          Basic information
        </Typography>
        <TextInput
          register={register}
          disabled={!!hasState}
          type="text"
          placeholder="Internal ID"
          name="internalId"
          validationSchema={{ required: 'This field is required' }}
          errors={errors}
          label="Internal ID"
          required={!hasState}
        />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <TextInput
              register={register}
              type="text"
              placeholder="Device model"
              name="model"
              label="Device model"
              validationSchema={{ required: 'This field is required' }}
              errors={errors}
              required={!hasState}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              register={register}
              type="text"
              placeholder="Device Name"
              name="name"
              label="Device Name"
            />
          </Grid>
        </Grid>
        <Typography variant="h6" marginBottom="10px" marginTop="20px">
          Location & status
        </Typography>
        {hasState && (
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={6}>
              <DropdownInput
                register={register}
                label="Current Location"
                options={dropdownOptions.location}
                name="currentLocation"
              />
            </Grid>
            <Grid item xs={6}>
              <DropdownInput
                register={register}
                label="Permanent Location"
                options={dropdownOptions.location}
                name="permanentLocation"
              />
            </Grid>
          </Grid>
        )}
        {!hasState && (
          <DropdownInput
            register={register}
            label="Location"
            options={dropdownOptions.location}
            name="location"
            required={!hasState}
            validationSchema={{ required: 'This field is required' }}
            errors={errors}
          />
        )}
        <DropdownInput
          register={register}
          label="Status"
          options={dropdownOptions.status}
          name="deviceStatus"
          required={!hasState}
          validationSchema={{ required: 'This field is required' }}
          errors={errors}
        />
        <Typography variant="h6" marginBottom="10px" marginTop="20px">
          Device details
        </Typography>
        <DropdownInput
          register={register}
          label="Type"
          options={dropdownOptions.type}
          name="deviceType"
          required={!hasState}
          validationSchema={{ required: 'This field is required' }}
          errors={errors}
        />
        <DropdownInput
          register={register}
          label="Platform"
          options={dropdownOptions.platform}
          name="devicePlatform"
          required={!hasState}
          validationSchema={{ required: 'This field is required' }}
          errors={errors}
        />
        <MeasurementInput
          register={register}
          name="size"
          unitName="sizeMeasuringUnit"
          validationSchema={{ required: 'This field is required' }}
          errors={errors}
          required={!hasState}
        />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <TextInput
              register={register}
              type="text"
              placeholder="OS Version"
              name="osVersion"
              label="OS Version"
              validationSchema={{ required: 'This field is required' }}
              errors={errors}
              required={!hasState}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              register={register}
              type="text"
              placeholder="Link on device"
              name="link"
              label="Link on device"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <TextInput
              register={register}
              type="text"
              placeholder="UDID"
              name="udid"
              label="UDID"
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              register={register}
              type="text"
              placeholder="IMEI"
              name="imei"
              label="IMEI"
            />
          </Grid>
        </Grid>
        <TextInput
          register={register}
          type="text"
          placeholder="Serial number"
          name="serialNumber"
          label="Serial number"
        />
        {watch('devicePlatform') === 'IOS' && (
          <Checkbox
            register={register}
            label="Find my phone is turned on?"
            checkboxLabel="Yes"
            name="findMyPhone"
          />
        )}
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="secondary" label="Cancel" onClick={onCancel} />
          </Grid>
          <Grid item>
            {!hasState ? (
              <Button variant="primary" label="Add new device" type="submit" />
            ) : (
              <Button variant="primary" label="Update device" type="submit" />
            )}
          </Grid>
        </Grid>
      </Form>

      <RequestedDeviceResponceStatus
        open={!!showRequestResponse}
        variant={showRequestResponse}
        deviceModel={deviceInput?.model}
        deviceOsVersion={deviceInput?.osVersion}
        deviceName={deviceInput?.name}
        devicePlatform={deviceInput?.devicePlatform}
        icon={showRequestResponse === 'success' ? <SuccessResponseIcon /> : <ErrorResponseIcon />}
        title={modalTitle}
        textBody={modalTextBody}
        headlineIcon={Icon.MOBILE_ICON}
        handleClose={handleModalClose}
      />
    </StyledMainContainer>
  );
};

const StyledMainContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  gap: theme.spacing(5),
  alignSelf: 'stretch',
  borderRadius: '8px',
  background: theme.palette.common.white,
  boxShadow: '3px 4px 16px 0px rgba(0, 0, 0, 0.09)',
  width: '100%',
}));

const StyledDeviceNameBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
}));

const GridBordered = styled(Grid)(({ theme }) => ({
  borderWidth: '2px',
  borderColor: '#80808054',
  borderStyle: 'solid',
  marginBottom: '40px',
}));
