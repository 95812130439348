// eslint-disable-next-line import/no-extraneous-dependencies
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

export const devicesColumns = [
  columnHelper.accessor('internalId', {
    header: () => 'Internal Id',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('model', {
    header: () => 'Model',
    cell: (info) => info.renderValue(),
  }),
  columnHelper.accessor('name', {
    header: () => 'Name',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('devicePlatform', {
    header: 'Platform',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('deviceType', {
    header: 'Type',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('deviceStatus', {
    header: 'Status',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('currentLocation', {
    header: 'Location',
    cell: (info) => info.getValue(),
  }),
];

export const myDevicesColumns = [
  columnHelper.accessor('deviceInternalId', {
    header: () => 'Internal Id',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('deviceName', {
    header: () => 'Device Name',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('deviceModel', {
    header: () => 'Model',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('size', {
    header: 'Size',
    cell: (info) => info.getValue(),
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('osVersion', {
    header: 'OS version',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('team', {
    header: 'Team owner',
    cell: (info) => info.getValue() || 'no team',
  }),
  columnHelper.accessor('timeStartingLoan', {
    header: 'Loan started:',
    cell: (info) => {
      const data = info.getValue();
      return `${data[2]}.${data[1]}.${data[0]}.`;
    },
  }),
  columnHelper.accessor('expirationDate', {
    header: 'Loan ending:',
    cell: (info) => info.getValue(),
  }),
];

export const employeeColumns = [
  columnHelper.accessor('name', {
    header: () => 'Name',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('surname', {
    header: () => 'Surname',
    cell: (info) => info.renderValue(),
  }),
  columnHelper.accessor('username', {
    header: () => 'Username',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('email', {
    header: 'Email',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('location', {
    header: 'Location',
    cell: (info) => info.getValue(),
  }),
];

export const deviceHistoryColumns = [
  columnHelper.accessor('deviceModel', {
    header: () => 'Device model',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('employeeBorrowingName', {
    header: () => 'Borrower name',
    cell: (info) => info.renderValue(),
  }),
  columnHelper.accessor('employeeBorrowingSurname', {
    header: () => 'Borrower surname',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('employeeGivingName', {
    header: 'Lender name',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('employeeGivingSurname', {
    header: 'Lender surname',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('timeStartLoan', {
    header: 'Loan started',
    cell: (info) => {
      const date = new Date(info.getValue());
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
  }),
];
