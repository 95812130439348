import {
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  styled,
  TextField,
} from '@mui/material';
import { useAuth } from 'context/auth/AuthProvider';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Icon } from 'core/ui';
import { Controller, useForm } from 'react-hook-form';
import { Visibility, VisibilityOff, LogoTextIcon } from './assets';
import { LogoIcon } from '../../core/assets';

export const LoginPage = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { setEmail, setPassword, hasLoginFailed, authenticateUser, setHasLoginFailed, logout } =
    useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveLogin = (data) => {
    setIsLoading(true);
    localStorage.setItem('email', data.email);
    localStorage.setItem('password', data.password);
    setEmail(data.email);
    setPassword(data.password);
    setHasLoginFailed(false);
  };

  const onSubmit = (data) => {
    saveLogin(data);
    authenticateUser();
  };

  const handleShowPasswordClick = () => setShowPassword((show) => !show);

  const defineIcon = () =>
    isLoading ? <CircularProgress size="24px" color="icon" /> : Icon.PLUS_CIRCLE_ICON;

  useLayoutEffect(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    if (hasLoginFailed) {
      setError('email', {
        type: {
          manual: 'Looks like email and password don’t match. Please try again.',
        },
      });
      setError('password', { type: 'manual', message: '' });
      setIsLoading(false);
    }
  }, [hasLoginFailed, setError]);

  const handleResetButtonClick = () => {
    // TODO needs add logic about  regenerate password through slack
    // for this we need email
    //
    // axios
    //   .get(baseURL + getNewPassword, {
    //     params: {
    //       email: getValues('email'),
    //     },
    //   })
    //   .then((res) => {
    //     setShow(true);
    //     setResponse(res.data);
    //   });
  };

  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledLogo />
        <LogoTextIcon />
      </StyledHeaderContainer>
      <StyledCard>
        <StyledCardContent>
          <StyledGrid container>
            <Grid xs={12} item>
              <Typography variant="h4">Welcome back!</Typography>
              <StyledTypography sx={{ marginTop: '12px' }} grey>
                Login to start borrowing devices.
              </StyledTypography>
              {errors?.email?.type?.manual && (
                <StyledTypography error>{errors.email.type.manual}</StyledTypography>
              )}
            </Grid>
            <StyledFormControlGrid xs={12} item>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: 'Email is required',
                  validate: {
                    email: (value) =>
                      /^[\w.]+@([\w]+\.)+[\w]{2,6}$/.test(value) ||
                      'Invalid Email Address - Please check for typos, ensure it includes "@" and a domain name (e.g., example.com), and avoid spaces or special characters.',
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <StyledFromControl>
                    <StyledLabel error={error}>Email Address</StyledLabel>
                    <TextField
                      error={error}
                      helperText={
                        <StyledErrorMessageTypography>
                          {error?.message}
                        </StyledErrorMessageTypography>
                      }
                      placeholder="Enter your e-mail"
                      value={value}
                      onChange={onChange}
                    />
                  </StyledFromControl>
                )}
              />
              <Controller
                control={control}
                name="password"
                rules={{
                  required: 'Password is required',
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <StyledFromControl>
                    <StyledLabel error={error}>Password</StyledLabel>
                    <TextField
                      error={error}
                      onChange={onChange}
                      value={value}
                      type={showPassword ? 'text' : 'password'}
                      helperText={
                        <StyledErrorMessageTypography>
                          {error?.message}
                        </StyledErrorMessageTypography>
                      }
                      placeholder="Enter your password"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPasswordClick}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                    />
                  </StyledFromControl>
                )}
              />
              <StyledResetPasswordGrid>
                <StyledTypography>Forgot password?</StyledTypography>
                <StyledLinkButton onClick={handleResetButtonClick} variant="text">
                  Reset password
                </StyledLinkButton>
              </StyledResetPasswordGrid>
            </StyledFormControlGrid>
            <StyledGridContent xs={12} item>
              <Button
                size="big"
                onClick={handleSubmit(onSubmit)}
                label="Sign In"
                icon={defineIcon()}
              />
            </StyledGridContent>
          </StyledGrid>
          <StyledAdminGridContent xs={12} item>
            <StyledTypography>Don’t have account?</StyledTypography>
            <StyledLinkButton variant="text" href="https://endava.slack.com/team/U048SP7LCSW">
              Contact Administrator
            </StyledLinkButton>
          </StyledAdminGridContent>
        </StyledCardContent>
      </StyledCard>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '1600px',
});

const StyledLabel = styled(FormLabel)(({ theme, error }) => ({
  ...theme.typography.label,
  color: error ? theme.palette.error.main : theme.palette.grey[600],
}));

const StyledLinkButton = styled(MuiButton)(({ theme }) => ({
  ...theme.typography.link,
  display: 'flex',
  justifyContent: 'flex-start',
  color: theme.palette.common.trueBlack,
  textAlign: 'left',
  textTransform: 'none',
  padding: 0,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: '463px',
  padding: '48px',
  borderRadius: '8px',
  gap: theme.spacing(3),
  boxShadow: '-2px 2px 16px 0px rgba(0, 0, 0, 0.12)',

  '& .MuiCardContent-root:last-child': {
    padding: 0,
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: 0,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(5),
  display: 'grid',
}));

const StyledFormControlGrid = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(3),
  display: 'grid',
}));

const StyledGridContent = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-start',
});

const StyledResetPasswordGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'end',
  gap: '12px',
});

const StyledAdminGridContent = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  borderTop: `1px solid ${theme.palette.grey[500]}`,
  paddingTop: theme.spacing(3),
}));

const StyledFromControl = styled(FormControl)(({ theme }) => ({
  color: theme.palette.grey[600],
  gap: theme.spacing(1),
  '& .MuiFormHelperText-root': {
    ...theme.typography.body1,
    fontWeight: 500,
    margin: 0,
    color: theme.palette.error.main,
  },
}));

const StyledTypography = styled(Typography)(({ theme, grey, error }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  fontWeight: 500,
  color: error
    ? theme.palette.error.main
    : grey
    ? theme.palette.grey[600]
    : theme.palette.common.trueBlack,
  display: 'flex',
  margin: 0,
}));

const StyledErrorMessageTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  marginTop: theme.spacing(1),
}));

const StyledHeaderContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: '40px',
});

const StyledLogo = styled(LogoIcon)({
  marginRight: '12px',
});
