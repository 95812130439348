import React, { useRef, useState } from 'react';
import { Label, Container, StyledFileInput, StyledButton } from './styled';

export const FileInput = ({
  disabled,
  label,
  name,
  type,
  placeholder,
  validationSchema,
  register = () => {},
}) => {
  const [file, setFile] = useState(null);
  const { ref, onChange, ...rest } = register(name, validationSchema);

  const inputRef = useRef(ref);
  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <StyledFileInput
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        onChange={(e) => {
          setFile(e.target.files[0].name);
          onChange(e);
        }}
        {...rest}
        ref={(e) => {
          ref(e);
          inputRef.current = e;
        }}
      />
      <StyledButton onClick={handleButtonClick}>{file || 'Choose a file'}</StyledButton>
    </Container>
  );
};
