import { Container, styled } from '@mui/material';
import { Button, Icon, Paper } from 'core/ui';

export const SearchAndFiltersContainer = ({
  title,
  titleIcon,
  children,
  buttonLabel,
  onClick,
  displayButton = true,
}) => (
  <Paper
    title={title}
    icon={titleIcon}
    rightButtons={
      displayButton && <Button label={buttonLabel} icon={Icon.PLUS_CIRCLE_ICON} onClick={onClick} />
    }
  >
    <StyledSearchedBarRow disableGutters>{children}</StyledSearchedBarRow>
  </Paper>
);

const StyledSearchedBarRow = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  alignSelf: 'stretch',
}));
