import { Box, Button, Typography, styled } from '@mui/material';
import { capitalize, first } from 'lodash';
import { theme } from 'theme';
import { formatDateToString } from '../../../core/util/dateFormatUtil';

export const Comment = ({ comment, onClick, isAdminOrTemporaryAdmin }) => (
  <StyledMainBox>
    <StyledNameAndIconAndButtonBox>
      <StyledNameAndIconBox>
        <LetterIconBox>{capitalize(first(comment.name))}</LetterIconBox>
        <StyledNameAndDateBox>
          <Typography variant="body2" sx={{ color: theme.palette.common.black }}>
            {comment.name} {comment.surname}
          </Typography>
          <Typography variant="label" sx={{ color: theme.palette.grey[500] }}>
            {formatDateToString(comment.noteMadeOnDate)}
          </Typography>
        </StyledNameAndDateBox>
      </StyledNameAndIconBox>
      {isAdminOrTemporaryAdmin && (
        <Button onClick={onClick} variant="text">
          <StyledResolveButton>Resolve</StyledResolveButton>
        </Button>
      )}
    </StyledNameAndIconAndButtonBox>
    <Typography variant="body2" sx={{ color: theme.palette.grey[600], fontWeight: 500 }}>
      {comment.note}
    </Typography>
  </StyledMainBox>
);

const StyledMainBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '804px',
  padding: theme.spacing(3),
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[400]}`,
  margin: 0,
}));

const StyledNameAndIconAndButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const StyledNameAndDateBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const StyledNameAndIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const StyledResolveButton = styled(Button)(({ theme }) => ({
  ...theme.typography.label,
  color: theme.palette.common.black,
  textAlign: 'center',
  textTransform: 'none',
  fontWeight: 800,
}));

const LetterIconBox = styled(Box)(({ theme: { palette } }) => ({
  width: '32px',
  height: '32px',
  backgroundColor: palette.info.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '6.4px',
  color: palette.info.main,
  fontWeight: 700,
  fontSize: '19.2px',
}));
