import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';

export const SidebarItem = ({ label, open, icon, onClick, isMatch }) => (
  <ListItem disablePadding>
    <StyledListItemButton open={open} onClick={onClick} active={isMatch && open}>
      <StyledListItemIcon open={open} active={isMatch && !open}>
        {icon}
      </StyledListItemIcon>
      <StyledListItemText primary={<Typography variant="body2">{label}</Typography>} open={open} />
    </StyledListItemButton>
  </ListItem>
);

SidebarItem.propTypes = {
  label: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  isMatch: PropTypes.bool,
};

SidebarItem.defaultProps = {
  isMatch: false,
};

const StyledListItemButton = styled(ListItemButton)(
  ({
    theme: {
      palette: { grey },
    },
    open,
    active,
  }) => ({
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    gap: '8px',
    maxWidth: 240,
    textWrap: open ? 'wrap' : 'nowrap',
    padding: '0px',
    backgroundColor: active ? grey[700] : 'none',
    borderRadius: '8px',
    border: active && `1px solid ${grey[500]}`,
    '&:hover': {
      backgroundColor: open && grey[700],
    },
  }),
);

const StyledListItemIcon = styled(ListItemIcon)(
  ({
    theme: {
      palette: { grey },
    },
    open,
    active,
  }) => ({
    minWidth: 0,
    mr: open ? 3 : 'auto',
    justifyContent: 'center',
    backgroundColor: active ? 'none' : grey[700],
    padding: '8px',
    borderRadius: '8px',
    border: active && `1px solid ${grey[500]}`,
    '&:hover': {
      backgroundColor: !open && grey[900],
    },
  }),
);

const StyledListItemText = styled(ListItemText)(
  ({
    theme: {
      palette: { common },
    },
    open,
  }) => ({
    opacity: open ? 1 : 0,
    color: common.white,
  }),
);
