import styled from 'styled-components';
import DatePicker from 'react-datepicker';

// dropdown input

export const TooltipText = styled.span`
  color: #000;
  width: 200px;
  line-height: 44px;
  border-radius: 3px;
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  width: 200px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  visibility: hidden;
  transition: visibility 0.1s, color 0.1s, background-color 0.1s, width 0.1s,
    padding 0.1s ease-in-out;
  position: absolute;
  top: calc(100% + 10px);
  left: 30px;
`;
export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 200px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
  }
`;

// measurment input

export const MeasurmentInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
`;

export const InputContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #bbb;
  border-radius: 5px;

  input[type='text'] {
    padding: 0.5rem;
    font-size: 1rem;
    border: none;
    background: transparent;
    border-left: 1px solid #bbb;
  }
  input[type='number'] {
    flex: 1;
    padding: 0.5rem;
    font-size: 1rem;
    border: none;
    background: transparent;
  }

  select {
    width: 70px;
    padding: 0.5rem;
    font-size: 1rem;
    border: none;
    border-left: 1px solid #bbb;
    background: transparent;
  }
`;

// file input
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px dashed #ddd;
`;

export const StyledFileInput = styled.input`
  display: none;
`;

export const StyledButton = styled.button`
  background-color: #ddd;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`;

// checkbox

export const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const CheckBoxInput = styled.input`
  height: 20px;
  display: inline-block;
`;

export const LabelText = styled.label`
  margin-right: 8px;
`;

export const CheckboxText = styled.label`
  margin-left: 8px;
`;

export const CheckboxContent = styled.div`
  display: flex;
  align-items: center;
`;

export const FormContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  margin: 5px;
`;

export const Input = styled.input`
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  outline: none;
  padding-left: 14px;
  font-size: 16px;
  font-style: normal;
`;

export const Label = styled.label`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-right: 10px;
`;

export const Select = styled.select`
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  font-style: normal;
  padding-left: 14px;
  font-weight: 400;
  outline: none;
  border: 1px solid #bbb;
  outline: none;
`;

export const Option = styled.option`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

export const ErrorText = styled.p`
  font-size: 12px;
  color: #cc0000;
  margin 0;
`;

// datepicker

export const DatePickerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
`;

export const StyledDatePicker = styled(DatePicker)`
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #bbb;
`;

// debounced input

export const StyledInput = styled.input`
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  align-self: left;
`;
