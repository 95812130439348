import {
  baseURL,
  confirmLoanAdmin,
  confirmLoanEmployee,
  declineRequestURL,
  returnLoan,
  teamAdminApprove,
} from 'SharedAddresses';
import { Api } from 'api/Api';
import { CreateNewMessage } from 'core/ui';
import { v4 as uuidv4 } from 'uuid';

const DeviceAction = {
  LOAN_CONFIRM_BY_ADMIN: 'confirm-admin',
  LOAN_CONFIRM_BY_EMPLOYEE: 'confirm-employee',
  LOAN_RETURN: 'return',
  LOAN_APPROVE_BY_TEAM_ADMIN: 'loan-team-admin-approving',
};

const messageBuilder = (title, color, { deviceModel, internalId, osVersion, devicePlatform }) => ({
  title,
  body: `${deviceModel}, ${internalId}, ${osVersion}, ${devicePlatform} `,
  id: uuidv4(),
  color,
});

export const approveLoan = (device, refreshPage) => {
  const { deviceId, borrowerId, lenderId, adminApproved, pendingStatus, deviceStatus } = device;
  const apiClient = new Api();
  const deviceLoanConfirm = {
    deviceId: parseInt(deviceId, 10),
    borrowerId: parseInt(borrowerId, 10),
  };
  const deviceLoanReturn = {
    deviceId: parseInt(deviceId, 10),
    lenderId: parseInt(lenderId, 10),
  };
  const deviceLoanConfirmFromEmployee = {
    deviceId: parseInt(deviceId, 10),
    lenderId: Number(lenderId),
  };
  const deviceLoanForApproving = {
    deviceId: parseInt(deviceId, 10),
    borrowerId: parseInt(borrowerId, 10),
    lenderId: parseInt(lenderId, 10),
  };

  const isPendingStatusRequesting = pendingStatus === 'REQUESTING';
  const isDeviceStatusFree = deviceStatus === 'FREE';

  const deviceAction = !adminApproved
    ? DeviceAction.LOAN_APPROVE_BY_TEAM_ADMIN
    : !isPendingStatusRequesting
    ? DeviceAction.LOAN_RETURN
    : !isDeviceStatusFree
    ? DeviceAction.LOAN_CONFIRM_BY_EMPLOYEE
    : DeviceAction.LOAN_CONFIRM_BY_ADMIN;

  if (deviceAction === DeviceAction.LOAN_CONFIRM_BY_ADMIN) {
    apiClient
      .post(baseURL + confirmLoanAdmin, { ...deviceLoanConfirm })
      .then(() => {
        CreateNewMessage(messageBuilder('Device approved', 'success', device));
        refreshPage();
      })
      .catch(() => {
        CreateNewMessage(messageBuilder('Device not approved', 'error', device));
      });
  } else if (deviceAction === DeviceAction.LOAN_CONFIRM_BY_EMPLOYEE) {
    apiClient
      .post(baseURL + confirmLoanEmployee, { ...deviceLoanConfirmFromEmployee })
      .then(() => {
        CreateNewMessage(messageBuilder('Device approved', 'success', device));
        refreshPage();
      })
      .catch(() => {
        CreateNewMessage(messageBuilder('Device not approved', 'error', device));
      });
  } else if (deviceAction === DeviceAction.LOAN_APPROVE_BY_TEAM_ADMIN) {
    apiClient
      .put(baseURL + teamAdminApprove, { ...deviceLoanForApproving })
      .then(() => {
        CreateNewMessage(messageBuilder('Device approved', 'success', device));
        refreshPage();
      })
      .catch(() => {
        CreateNewMessage(messageBuilder('Device not approved', 'error', device));
      });
  } else {
    apiClient
      .put(baseURL + returnLoan, { ...deviceLoanReturn })
      .then(() => {
        CreateNewMessage(messageBuilder('Device returned', 'success', device));
        refreshPage();
      })
      .catch(() => {
        CreateNewMessage(messageBuilder('Device not able to return', 'error', device));
      });
  }
};

export const declineLoan = (device, refreshPage) => {
  const { id } = device;
  const apiClient = new Api();
  apiClient
    .delete(baseURL + declineRequestURL + id)
    .then(() => {
      CreateNewMessage(messageBuilder('Device declined', 'success', device));
      refreshPage();
    })
    .catch(() => {
      CreateNewMessage(messageBuilder('Device not able to decline', 'error', device));
    });
};
