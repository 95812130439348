import { Backdrop, Container, Typography, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { CloseIcon } from '../assets';

export const DatePicker = ({ open, handleClose, onChange }) => (
  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDemoItem>
        <CloseIcon style={{ alignSelf: 'end' }} onClick={handleClose} />
        <Typography variant="h6">Chose loan date</Typography>
        <DateCalendar
          defaultValue={dayjs().add(7, 'day')}
          onChange={(newValue) => onChange(newValue)}
          disablePast
        />
      </StyledDemoItem>
    </LocalizationProvider>
  </Backdrop>
);

const StyledDemoItem = styled(Container)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(5),
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  borderRadius: '8px',
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  width: 'fit-content',
}));
