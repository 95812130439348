import { Chip, styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const DeviceStatus = {
  FREE: 'Available',
  BORROWED: 'Borrowed',
  PENDING: 'Pending',
  LEGACY: ['Not working', 'In repair', 'Legacy device', 'Lost'],
};

export const StatusChip = ({ status }) => <StyledChip label={status} />;

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
};

const resolveBackgroundColor = (status, palette) => {
  switch (status) {
    case DeviceStatus.FREE:
      return palette.success.light;
    case DeviceStatus.BORROWED:
      return palette.error.light;
    case DeviceStatus.PENDING:
      return palette.common.yellow;
    default:
      return palette.info.light;
  }
};

const StyledChip = styled(Chip)(({ theme: { palette }, label }) => ({
  color: label === DeviceStatus.FREE ? palette.grey[900] : palette.grey[700],
  backgroundColor: `${resolveBackgroundColor(label, palette)}`,
  fontWeight: 600,
  lineHeight: '16px',
  fontSize: '14px',
  padding: '8px 4px',
}));
