import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { capitalize } from 'lodash';
import { Button, DatePicker, StatusChip, RequestedDeviceResponceStatus } from 'core/ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, Status } from 'core/ui/constants';
import { useMutation } from '@tanstack/react-query';
import { Api } from 'api/Api';
import { baseURL, requestFromAdmin } from 'SharedAddresses';
import { TableSkeleton } from './components';
import { Statuses, TableColumns } from './consts';
import { ErrorResponseIcon, SuccessResponseIcon } from '../../core/assets';

export const DevicesTable = ({ devices, isLoading }) => {
  const apiClient = new Api();
  const [deviceForRequesting, setDeviceForRequesting] = useState(null);
  const [openCalendarComponent, setOpenCalendarComponent] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showRequestResponse, setShowRequestResponse] = useState(null);

  const requestNewDevice = useMutation({
    mutationKey: ['requestDevice'],
    mutationFn: (data) => apiClient.post(baseURL + requestFromAdmin, { ...data }),
    onSuccess: () => {
      setOpenCalendarComponent(false);
      setShowRequestResponse('success');
    },
    onError: () => {
      setOpenCalendarComponent(false);
      setShowRequestResponse('error');
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeDate = (newValue) => {
    requestNewDevice.mutate({ deviceId: deviceForRequesting.id, loanEndDate: newValue });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const navigate = useNavigate();

  const handleClickRow = (id) => {
    navigate(`/device-detail/${id}`);
  };

  return (
    <StyledTable>
      <TableHead>
        <StyledHeadRow>
          {TableColumns.map((column) => (
            <StyledTableCell>
              <StyledTypographyLabel>{column.label} </StyledTypographyLabel>
            </StyledTableCell>
          ))}
        </StyledHeadRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <TableSkeleton numberOfRows={4} />
        ) : (
          devices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((device) => (
            <StyledTableRow hover key={device.id}>
              {TableColumns.map((column) => (
                <StyledBodyTableCell
                  onClick={() => column.label !== '' && handleClickRow(device.id)}
                >
                  {column.label === 'Location' ? (
                    capitalize(device[column.value])
                  ) : column.label === 'Status' ? (
                    <StatusChip status={Statuses[device[column.value]].label} />
                  ) : column.label === '' ? (
                    <Button
                      isDisabled={device.deviceStatus === 'BORROWED'}
                      onClick={() => {
                        setDeviceForRequesting(device);
                        setOpenCalendarComponent(true);
                      }}
                      label="Request"
                      variant="secondary"
                    />
                  ) : (
                    device[column.value]
                  )}
                </StyledBodyTableCell>
              ))}
            </StyledTableRow>
          ))
        )}
        <DatePicker
          open={openCalendarComponent}
          handleClose={() => setOpenCalendarComponent(false)}
          onChange={handleChangeDate}
        />

        <RequestedDeviceResponceStatus
          open={!!showRequestResponse}
          variant={showRequestResponse}
          deviceModel={deviceForRequesting?.model}
          deviceOsVersion={deviceForRequesting?.osVersion}
          deviceName={deviceForRequesting?.name}
          devicePlatform={deviceForRequesting?.devicePlatform}
          icon={showRequestResponse === 'success' ? <SuccessResponseIcon /> : <ErrorResponseIcon />}
          title={showRequestResponse === 'success' ? 'Device requested!' : 'Requested failed!'}
          textBody={
            showRequestResponse === 'success'
              ? 'After admin approval device will be assigned to you and you will be able to pick it up from device Library.'
              : 'Please try again or reach to admin for help.'
          }
          headlineIcon={Icon.MOBILE_ICON}
          handleClose={() => setShowRequestResponse(null)}
        />
      </TableBody>
      {!isLoading && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={devices?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </StyledTable>
  );
};

const StyledTypographyLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  color: theme.palette.grey[600],
}));

const StyledTable = styled(Table)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(4),
  flexDirection: 'column',
  alignSelf: 'stretch',
  borderRadius: '8px',
  background: theme.palette.common.white,
  boxShadow: '3px 4px 16px 0px rgba(0, 0, 0, 0.09)',
}));

const StyledRow = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(1)} 0px`,
  gap: theme.spacing(1),
  alignSelf: 'stretch',
}));

const StyledHeadRow = styled(StyledRow)(({ theme }) => ({
  alignItems: 'flex-start',
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
}));

const StyledTableRow = styled(StyledRow)({
  justifyContent: 'center',
  alignItems: 'center',
  height: '70px',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  alignItems: 'center',
  gap: '12px',
  flex: '1 0 0',
  borderBottom: 'none',
}));

const StyledBodyTableCell = styled(StyledTableCell)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  color: theme.palette.common.black,
}));
