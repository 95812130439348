/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import { createContext, useContext, useMemo, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../api/Api';
import { baseURL, getCurrentUser } from '../../SharedAddresses';

const AuthContext = createContext({
  user: null,
  isAdmin: false,
  isTemporaryAdmin: false,
  isAdminOrTemporaryAdmin: false,
  location: null,
  email: null,
  hasLoginFailed: false,
  setEmail: (email) => {},
  setPassword: (password) => {},
  setLocation: (location) => {},
  logout: () => {},
  authenticateUser: () => {},
  setHasLoginFailed: () => {},
});

export const AuthProvider = ({ children }) => {
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [password, setPassword] = useState(localStorage.getItem('password'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [location, setLocation] = useState(localStorage.getItem('location'));
  const [hasLoginFailed, setHasLoginFailed] = useState(false);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = useCallback(
    (data) => {
      setUser(data);
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('location', data.location);
      setLocation(data.location);
      navigate('/home');
    },
    [navigate, setUser],
  );

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    setUser(null);
    setEmail(null);
    setPassword(null);
    setLocation(null);
    localStorage.setItem('email', null);
    localStorage.setItem('password', null);
    localStorage.setItem('user', null);
    localStorage.setItem('location', null);
    navigate('/login', { replace: true });
  }, [navigate, setPassword, setUser, setEmail]);

  const isAdmin = useMemo(() => user?.listOfEmployeeRoles?.includes('ADMIN'), [user]);
  const isTemporaryAdmin = useMemo(
    () => user?.listOfEmployeeRoles?.includes('TEMPORARY_ADMIN'),
    [user],
  );

  const isAdminOrTemporaryAdmin = useMemo(
    () =>
      user?.listOfEmployeeRoles?.includes('ADMIN') ||
      user?.listOfEmployeeRoles?.includes('TEMPORARY_ADMIN'),
    [user],
  );

  const authenticateUser = () => {
    const apiClient = new Api();
    apiClient
      .post(baseURL + getCurrentUser, {})
      .then((response) => {
        login(response.data);
        setHasLoginFailed(false);
      })
      .catch((err) => {
        logout();
        setHasLoginFailed(true);
      });
  };

  const value = useMemo(
    () => ({
      user,
      isAdmin,
      isTemporaryAdmin,
      isAdminOrTemporaryAdmin,
      email,
      setEmail,
      location,
      setLocation,
      setPassword,
      logout,
      hasLoginFailed,
      authenticateUser,
      setHasLoginFailed,
    }),
    [
      user,
      isAdmin,
      isTemporaryAdmin,
      isAdminOrTemporaryAdmin,
      email,
      setEmail,
      setPassword,
      logout,
      location,
      setLocation,
      hasLoginFailed,
      authenticateUser,
      setHasLoginFailed,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
