import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/auth/AuthProvider';

export const ProtectedRoute = ({ children, onlyForAdmin = false }) => {
  const { user, isAdminOrTemporaryAdmin } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  if (onlyForAdmin && !isAdminOrTemporaryAdmin) {
    // page is for admin and user is not admin
    return <Navigate to="/404" />;
  }

  return children;
};
