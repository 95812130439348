import { FormControl, FormLabel, InputAdornment, OutlinedInput, styled } from '@mui/material';
import { useState } from 'react';
import { Button } from './Button';
import { Icon } from './constants';

export const TextInput = ({ onSave }) => {
  const [comment, setComment] = useState('');

  const onClick = () => {
    onSave(comment);
    setComment('');
  };

  const handleOnChange = (e) => {
    setComment(e.target.value);
  };

  return (
    <StyledFormControl>
      <StyledLabel> Add comment</StyledLabel>
      <StyledOutlinedInput
        multiline
        maxRows={3}
        value={comment}
        onChange={handleOnChange}
        endAdornment={
          <StyledInputAdorment marginBottom position="end">
            <Button
              isDisabled={!(comment.length > 0)}
              icon={Icon.RIGHT_ARROW_ICON}
              onClick={onClick}
              label="Submit"
            />
          </StyledInputAdorment>
        }
        startAdornment={
          <StyledInputAdorment position="start">
            <StyledLabel>{comment.length}/100</StyledLabel>
          </StyledInputAdorment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
          maxLength: 100,
        }}
      />
    </StyledFormControl>
  );
};

const StyledLabel = styled(FormLabel)(({ theme }) => ({
  ...theme.typography.label,
}));

const StyledFormControl = styled(FormControl)({
  padding: '16px 24px',
  gap: '4px',
});

const StyledInputAdorment = styled(InputAdornment)(({ marginBottom }) => ({
  alignSelf: 'flex-end',
  marginBottom: marginBottom ? '16px' : '',
}));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme: { palette } }) => ({
  padding: '16px 24px',
  height: '80px',
  '&.Mui-focused': {
    border: `3px solid ${palette.action.focus}`,
  },
}));
