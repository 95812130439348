import { createTheme } from '@mui/material/styles';

const greyscale = {
  white: '#FFFFFF',
  grey300: '#F9F9F9',
  grey400: '#E7E7E7',
  grey500: '#B8B8B8',
  grey600: '#707070',
  grey700: '#3A3A3A',
  grey900: '#2D2D2D',
  black: '#141515',
  trueBlack: '#000000',
};

export const theme = createTheme({
  palette: {
    error: {
      main: '#CF222E',
      light: '#FFEBE9',
      contrastText: greyscale.black,
    },
    success: {
      main: '#00AC31',
      light: '#D6FFDE',
      contrastText: greyscale.black,
    },
    info: {
      main: '#009AF1',
      light: '#DDF4FF',
      dark: '#0969DA',
    },
    grey: {
      300: greyscale.grey300,
      400: greyscale.grey400,
      500: greyscale.grey500,
      600: greyscale.grey600,
      700: greyscale.grey700,
      900: greyscale.grey900,
    },
    primary: {
      main: greyscale.black,
      contrastText: greyscale.white,
    },
    secondary: {
      main: greyscale.grey400,
      contrastText: greyscale.black,
    },
    common: {
      white: greyscale.white,
      black: greyscale.black,
      trueBlack: greyscale.trueBlack,
      yellow: '#FFF8C5',
    },
    action: {
      focus: '#009AF1',
    },
    icon: {
      main: greyscale.white,
      darkYellow: '#BF8700',
      lightYellow: '#FFF8C5',
      darkGreen: '#00AC31',
      lightGreen: '#D6FFDE',
      darkBlue: '#009AF1',
      lightBlue: '#DDF4FF',
    },
  },
  typography: {
    fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '19px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    body2: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    h1: {
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: '58px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    h2: {
      fontSize: '40px',
      fontWeight: 700,
      lineHeight: '48px',
      letterSpacing: '-0.01em',
      textAlign: 'left',
    },
    h3: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '38px',
      letterSpacing: '-0.01em',
      textAlign: 'left',
    },
    h4: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '34px',
      letterSpacing: '-0.01em',
      textAlign: 'left',
    },
    h5: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '29px',
      letterSpacing: '-0.01em',
      textAlign: 'left',
    },
    h6: {
      fontFamily: 'Montserrat',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '-0.01em',
      textAlign: 'left',
    },
    button: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '-0.02em',
      textAlign: 'center',
    },
    label: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '-0.15px',
      textAlign: 'left',
    },
    link: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '24px',
    },
  },
});
