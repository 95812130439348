import * as React from 'react';
import { useState } from 'react';
import { Box, Drawer, List, styled } from '@mui/material';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'context/auth/AuthProvider';
import { LogoIcon } from 'core/assets';
import { CollapseIcon, ExpandIcon, LogoTextIcon } from './assets';
import { SidebarItem } from './components';
import { SidebarOptions } from './constants';

const drawerWidth = 218;

export const SidebarMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAdminOrTemporaryAdmin } = useAuth();

  const [open, setOpen] = useState(false);

  const navigateTo = (path) => {
    navigate(path);
  };

  const handleMenuChange = () => {
    setOpen(!open);
  };

  const isMatch = (itemPath) => !!matchPath(itemPath, pathname);

  return (
    <Box sx={{ display: 'flex' }}>
      <StyledDrawer variant="permanent" open={open}>
        <StyledDrawerHeader>
          <StyledLogoIcon open={open} />
          {open && <LogoTextIcon />}
        </StyledDrawerHeader>
        <StyledList open={open}>
          {Object.entries(SidebarOptions).map(([key, { label, path, icon, isOnlyForAdmin }]) =>
            isAdminOrTemporaryAdmin || !isOnlyForAdmin ? (
              <SidebarItem
                key={key}
                label={label}
                icon={icon}
                open={open}
                isMatch={isMatch(path)}
                onClick={() => navigateTo(path)}
              />
            ) : undefined,
          )}
        </StyledList>
        <StyledDrawerFooter open={open}>
          <SidebarItem
            label="Collapse Menu"
            icon={open ? <CollapseIcon /> : <ExpandIcon />}
            open={open}
            onClick={handleMenuChange}
          />
        </StyledDrawerFooter>
      </StyledDrawer>
    </Box>
  );
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '88px',
});

const StyledDrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '40px',
});

const StyledDrawerFooter = styled('div')(({ open }) => ({
  marginBottom: '40px',
  padding: open ? '0px 16px' : '0px 24px',
}));

const StyledLogoIcon = styled(LogoIcon)(({ open }) => ({
  marginRight: open ? '12px' : '0px',
}));

const StyledList = styled(List)(({ open }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: open ? '0px 16px' : '0px 24px',
}));

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, theme: { palette }, open }) => ({
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        backgroundColor: palette.primary.main,
        ...openedMixin(theme),
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        backgroundColor: palette.primary.main,
        ...closedMixin(theme),
      },
    }),
  }),
);
