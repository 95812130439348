import React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from './styled';

export default function FormContainer({ defaultValues, children, onSubmit }) {
  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {children.map((child) => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
              },
            })
          : child;
      })}
      {/*

      // future work
      // This is the code that I want to use, but it doesn't work.
      // I want to be able to pass in a form with nested rows and have it work.


      {children.map((child) => {
        if (child.type.displayName === 'Row') {
          return React.createElement(
            child.type,
            { key: child.props.name },
            child.props.children.map((nestedChild) => {
              return nestedChild.props.name
                ? React.createElement(nestedChild.type, {
                    ...{
                      ...nestedChild.props,
                      register: methods.register,
                      key: nestedChild.props.name,
                      disabled: nestedChild.props.disabled,
                    },
                  })
                : nestedChild;
            }),
          );
        }

        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
                disabled: child.props.disabled,
              },
            })
          : child;
      })} */}
    </Form>
  );
}
