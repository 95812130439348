import { FormControl, FormLabel, IconButton, TextField, styled } from '@mui/material';
import { Icon } from '../ui';
import { SearchIcon } from '../assets';

export const SearchField = ({ onChange, value, showCancelIcon, onCancel }) => (
  <StyledSearchFieldControl>
    <StyledLabel>Search</StyledLabel>
    <StyledTextField
      placeholder="Type something"
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: <SearchIcon />,
        endAdornment: showCancelIcon ? (
          <IconButton edge="end" onClick={onCancel}>
            {Icon.CLOSE_ICON}
          </IconButton>
        ) : null,
      }}
    />
  </StyledSearchFieldControl>
);

const StyledTextField = styled(TextField)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  background: theme.palette.common.white,
  alignSelf: 'stretch',

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${theme.palette.grey[600]}`,
      borderRadius: '8px',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.action.focus,
    },
  },

  '& .MuiInputBase-input': {
    padding: `12px ${theme.spacing(2)}`,
  },
}));

const StyledSearchFieldControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  width: '345px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
}));

const StyledLabel = styled(FormLabel)(({ theme }) => ({
  ...theme.typography.label,
  color: theme.palette.grey[600],
  display: 'flex',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
}));
