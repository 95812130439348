import React, { useCallback, useState } from 'react';
import Moment from 'moment';
import { useForm } from 'react-hook-form';
import { Api } from '../../api/Api';
import 'react-datepicker/dist/react-datepicker.css';
import { baseURL, historyOfDevice } from '../../SharedAddresses';
import TextInput from '../../components/inputs/TextInput';
import { Form } from '../../components/forms/styled';
import { DatePickerInput } from '../../components/inputs/DatePickerInput';
import CenteredSpinner from '../../components/loaders/Loader';
import { DeviceLibraryTable } from '../../components/table/DeviceLibraryTable';
import { deviceHistoryColumns } from '../../components/table/table_data';
import { PageTitle } from '../../components/PageTitle';
import { Row } from '../list-of-devices/styled';

const DeviceHistory = () => {
  const [loans, setLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    since: new Date().setFullYear(new Date().getFullYear() - 1),
    until: new Date(),
  };

  const fetchDeviceHistory = useCallback((data) => {
    const apiClient = new Api();
    setIsLoading(true);

    const params = {
      since: Moment(data.since).format('YYYY-MM-DD'),
      until: Moment(data.until).format('YYYY-MM-DD'),
      'device-id': data.deviceId,
    };

    apiClient
      .get(baseURL + historyOfDevice, params)
      .then((response) => {
        setLoans(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const { handleSubmit: submitForm, control, register } = useForm({ defaultValues });

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <PageTitle>Device History</PageTitle>
      <Form onSubmit={submitForm(fetchDeviceHistory)}>
        <Row>
          <DatePickerInput name="since" label="From date:" control={control} />
          <DatePickerInput name="until" label="Until date:" control={control} />
          <TextInput
            name="deviceId"
            label="Device id"
            type="number"
            placeholder="Enter id"
            register={register}
          />
        </Row>
        <button type="submit">Submit</button>
      </Form>

      {loans.length === 0
        ? isLoading && <CenteredSpinner />
        : !isLoading && <DeviceLibraryTable data={loans} columns={deviceHistoryColumns} />}
    </>
  );
};

export default DeviceHistory;
