import { Skeleton, TableCell, TableRow, styled } from '@mui/material';
import { times } from 'lodash';

export const TableSkeleton = ({ numberOfRows }) => {
  const rows = times(numberOfRows, (index) => (
    <StyledTableRow key={index}>
      <StyledTableCell component="th" scope="row">
        <StyledSkeleton animation="wave" variant="rounded" />
      </StyledTableCell>
      <StyledTableCell>
        <StyledSkeleton animation="wave" variant="rounded" />
      </StyledTableCell>
      <StyledTableCell>
        <StyledSkeleton animation="wave" variant="rounded" />
      </StyledTableCell>
      <StyledTableCell>
        <StyledSkeleton animation="wave" variant="rounded" />
      </StyledTableCell>
      <StyledTableCell>
        <StyledSkeleton animation="wave" variant="rounded" />
      </StyledTableCell>
      <StyledTableCell>
        <StyledSkeleton animation="wave" variant="rounded" />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  ));

  return <div>{rows}</div>;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(1)} 0px`,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  alignSelf: 'stretch',
  borderRadius: '8px',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  alignItems: 'flex-start',
  gap: '12px',
  flex: '1 0 0',
  borderBottom: 'none',
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '80px',
  height: theme.spacing(2),
  borderRadius: theme.spacing(3),
  background: `linear-gradient(90deg, ${theme.palette.grey[400]} 0%, rgba(231, 231, 231, 0.75) 0.01%, rgba(231, 231, 231, 0.33) 118.13%)`,
}));
