export const dropdownOptions = {
  location: [
    { choice: 'Zagreb', value: 'ZAGREB' },
    { choice: 'Split', value: 'SPLIT' },
    { choice: 'Osijek', value: 'OSIJEK' },
  ],
  status: [
    { choice: 'Device is free for borrowing', value: 'FREE' },
    { choice: 'Device is in a box', value: 'IN_A_BOX' },
    { choice: `Device doesn't work`, value: 'NOT_WORKING' },
    { choice: 'Device is being repaired', value: 'IN_REPAIR' },
  ],
  type: [
    { choice: 'Mobile', value: 'MOBILE' },
    { choice: 'Tablet', value: 'TABLET' },
    { choice: 'Watch', value: 'WATCH' },
    { choice: 'Other', value: 'OTHER' },
  ],
  platform: [
    { choice: 'Android', value: 'ANDROID' },
    { choice: 'iOS', value: 'IOS' },
    { choice: 'Other', value: 'OTHER' },
  ],
  role: [
    { choice: 'Admin', value: 'ADMIN' },
    { choice: 'Temporary admin', value: 'TEMPORARY_ADMIN' },
    { choice: 'Team admin', value: 'TEAM_ADMIN' },
    { choice: 'User', value: 'USER' },
  ],
  measureUnit: [
    { choice: 'in', value: 'INCH' },
    { choice: 'cm', value: 'CM' },
  ],
};
