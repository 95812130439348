import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

export const LoaderContainer = styled.div`
  padding-top: 20%;
  text-align: center;
`;

export const ErrorMessagesHolder = styled.div`
  border: 2px solid blue;
  border-radius: 5px;
  text-align: left;
  padding: 10px;
  width: fit-content;
`;
export const ButtonErrorsContainer = styled.div`
  text-align: right;
`;
