import { Subject } from 'rxjs';

const subject = new Subject();

const initialState = {
  status: '',
  data: [],
  newDataCount: 0,
  error: '',
};

let state = initialState;

const notificationStore = {
  init: () => subject.next(state),
  subscribe: (setState) => subject.subscribe(setState),
  createNewNotification: (message) => {
    state = {
      ...state,
      data: [...state.data, message],
    };
    subject.next(state);
  },
  clearChat: () => {
    state = initialState;
    subject.next(state);
  },
  clearMessage: (data) => {
    state = {
      ...state,
      data: state.data.filter((message) => message.id !== data),
    };
    subject.next(state);
  },
  initialState,
};

export default notificationStore;
