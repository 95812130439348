import { Container, Typography, styled } from '@mui/material';
import { Button } from 'core/ui';
import { useNavigate } from 'react-router-dom';
import { GreyDevicesIcon } from '../assets';

export const Placeholder = ({ variant, title, message, icon }) => {
  const containsButton = variant === 'BORROWED_DEVICES';
  const navigate = useNavigate();
  const handleOnClick = () => navigate('/all-devices');

  return (
    <StyledContainer>
      {icon}
      <StyledText>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1">{message}</Typography>
        {containsButton && (
          <StyledButtonContainer>
            <Button
              size="big"
              icon={<GreyDevicesIcon />}
              label="All devices"
              onClick={handleOnClick}
            />
          </StyledButtonContainer>
        )}
      </StyledText>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
}));

const StyledText = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const StyledButtonContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));
