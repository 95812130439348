import React from 'react';
import { Controller } from 'react-hook-form';
import { DatePickerContainer, StyledDatePicker, Label } from './styled';

export const DatePickerInput = ({ label, name, control }) => {
  return (
    <DatePickerContainer>
      <Label>{label}</Label>
      <Controller
        render={({ field: { onChange, value } }) => (
          <StyledDatePicker
            dateFormat="dd/MM/yyyy"
            selected={value}
            onChange={onChange}
            name={name}
          />
        )}
        name={name}
        control={control}
      />
    </DatePickerContainer>
  );
};
