import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useZxing } from 'react-zxing';
import { Api } from '../../api/Api';
import { baseURL, devicesURL, requestFromAdmin } from '../../SharedAddresses';
import {
  ModalShadow,
  Modal,
  ModalBanner,
  ModalContent,
  ModalFooter,
  ButtonGroup,
  Tab,
} from '../../components/modals/styled';

export const QrScanner = () => {
  const navigate = useNavigate();

  const [result, setResult] = useState('');
  const [deviceDetail, setDeviceDetail] = useState({
    id: null,
    name: '',
    model: '',
    osVersion: '',
    size: 0.0,
    sizeMeasuringUnit: '',
    devicePlatform: '',
    deviceType: '',
    link: '',
    updateTimestamp: '',
    internalId: '',
    serialNumber: '',
    udid: '',
    imei: '',
    teamName: '',
    findMyPhone: '',
  });
  const [show, setShow] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [replyMessage, setReplyMessage] = useState({
    id: null,
    deviceId: null,
    deviceModel: '',
    borrowerEmail: '',
    adminEmail: '',
    pendingStatus: '',
    errors: '',
  });
  const chosenTab = ['About', 'Specifications'];
  const [active, setActive] = useState(chosenTab[0]);

  const { ref } = useZxing({
    onResult(vidaoResult) {
      setResult(vidaoResult.getText());
    },
  });

  useEffect(() => {
    const apiClient = new Api();
    if (result.length !== 0) {
      apiClient.get(baseURL + devicesURL + result).then((response) => {
        setDeviceDetail(response.data);
        setShow(true);
      });
    }
  }, [result]);

  const requestDeviceThroughQr = () => {
    const apiClient = new Api();
    const deviceRequest = { deviceId: parseInt(deviceDetail.id, 10) };
    setShowResponseMessage(true);
    apiClient
      .post(baseURL + requestFromAdmin, { ...deviceRequest })
      .then((response) => {
        setReplyMessage(response.data);
      })
      .catch((error) => {
        setReplyMessage(error.response.data);
      });
    setTimeout(() => {
      navigate('/my-devices');
    }, 3000);
  };

  const handleClose = () => setShow(false);

  return show === false ? (
    <video ref={ref} />
  ) : (
    <>
      <ModalShadow onClick={handleClose} />
      <Modal>
        <ModalBanner>
          <h1>{deviceDetail.name}</h1>
          <h3 style={{ paddingLeft: '10px' }}>{deviceDetail.model}</h3>
        </ModalBanner>
        <ModalContent>
          <ButtonGroup>
            {chosenTab.map((type) => (
              <Tab key={type} active={active === type} onClick={() => setActive(type)}>
                {type}
              </Tab>
            ))}
          </ButtonGroup>
          {
            {
              About: (
                <>
                  <h6>OS version: {deviceDetail.osVersion}</h6>
                  <h6>
                    Size: {deviceDetail.size} {deviceDetail.sizeMeasuringUnit}
                  </h6>
                  <h6>Device platform: {deviceDetail.devicePlatform}</h6>
                  <h6>Device type: {deviceDetail.deviceType}</h6>
                  <h6>
                    Link:{' '}
                    {deviceDetail.link ? (
                      <Link to={deviceDetail.link}> {deviceDetail.link} </Link>
                    ) : (
                      <p>not defined </p>
                    )}
                  </h6>
                  <h6>
                    Last time admin had device:{' '}
                    {deviceDetail.updateTimestamp === undefined
                      ? null
                      : `${deviceDetail.updateTimestamp[2]}.${deviceDetail.updateTimestamp[1]}.${deviceDetail.updateTimestamp[0]}.`}
                  </h6>
                </>
              ),
              Specifications: (
                <>
                  <h6>Internal id: {deviceDetail.internalId}</h6>
                  <h6>Serial number: {deviceDetail.serialNumber}</h6>
                  <h6>UDID: {deviceDetail.udid}</h6>
                  <h6>IMEI: {deviceDetail.imei}</h6>
                  <h6>Team owner: {deviceDetail.teamName}</h6>
                  <h6>Find my phone: {deviceDetail.findMyPhone}</h6>
                </>
              ),
            }[active]
          }
        </ModalContent>
        <ModalFooter>
          <button type="button" onClick={requestDeviceThroughQr}>
            Borrow device
          </button>
          {showResponseMessage && (replyMessage.errors || []).length === 0
            ? `Device ${replyMessage.deviceModel} is being requested. Please wait admin approval!`
            : replyMessage.errors}
        </ModalFooter>
      </Modal>
    </>
  );
};
