import React from 'react';
import { Button as MuiButton, styled } from '@mui/material';
import PropTypes from 'prop-types';

const ButtonVariant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const ButtonSize = {
  SMALL: 'small',
  BIG: 'big',
};

export const Button = ({
  variant,
  isDisabled,
  size,
  isLoading,
  label,
  icon,
  onClick,
  style,
  type,
}) => (
  <StyledButton
    variant={variant}
    disabled={isDisabled || isLoading}
    startIcon={icon}
    onClick={onClick}
    size={size}
    isDisabled={isDisabled}
    isLoading={isLoading}
    style={style}
    type={type}
  >
    {label}
  </StyledButton>
);
Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  variant: PropTypes.string,
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  isLoading: false,
  isDisabled: false,
  variant: ButtonVariant.PRIMARY,
  size: ButtonSize.SMALL,
  icon: null,
};

const StyledButton = styled(MuiButton)(
  ({ theme: { palette }, isDisabled, size, isLoading, variant }) => ({
    height: size === ButtonSize.SMALL ? '40px' : '56px',
    backgroundColor: `${palette[variant].main}${isLoading ? '80' : ''}`,
    opacity: isDisabled ? 0.5 : 1,
    color: palette[variant].contrastText,
    padding: size === ButtonSize.SMALL ? '8px 12px' : '16px 24px',
    borderRadius: '4px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: variant === ButtonVariant.PRIMARY ? palette.grey[900] : palette.grey[500],
    },
    '&:focus': {
      border: `3px solid ${palette.action.focus}`,
    },
    '& .MuiButton-startIcon': {
      color: variant === ButtonVariant.PRIMARY ? palette.grey[600] : palette.common.black,
    },
    '&.Mui-disabled': {
      color:
        variant === ButtonVariant.PRIMARY
          ? palette.primary.contrastText
          : `${palette.secondary.contrastText}80`,
    },
  }),
);
