import { Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { baseURL, getAllTeamsURL, teamUrl } from 'SharedAddresses';
import { Api } from 'api/Api';
import { CreateNewMessage, EditableTitle, Icon, Loading, Paper } from 'core/ui';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const TeamsPage = () => {
  const apiClient = new Api();
  const queryClient = useQueryClient();
  const [activeFieldId, setActiveFieldId] = useState(null);

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ['teams'],
    queryFn: () => apiClient.get(baseURL + getAllTeamsURL).then((res) => res.data),
    refetchOnWindowFocus: false,
  });

  const teamNameMutation = useMutation({
    mutationFn: ({ teamId, newName }) =>
      apiClient.put(baseURL + teamUrl + teamId, null, { 'new-name': newName }),
    onSuccess: () => {
      CreateNewMessage({
        title: 'Team name updated',
        body: 'Your changes have been applied successfully.',
        id: uuidv4(),
        color: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['teams'] });
    },
    onError: (error) => {
      CreateNewMessage({
        title: 'Not able to update team name.',
        body: [error.message],
        id: uuidv4(),
        color: 'error',
      });
    },
  });

  if (isLoading || teamNameMutation.isLoading || isFetching) {
    return <Loading />;
  }

  if (error) return `An error has occurred: ${error.message}`;

  const handleSaveClick = ({ formInput, id }) => {
    teamNameMutation.mutate({ newName: formInput, teamId: id });
  };

  return data ? (
    <Paper title="Teams" icon={Icon.EMPLOYEES_ICON}>
      <Grid container spacing={2}>
        {data.map(({ id, name }) => (
          <Grid item xs={6} key={id}>
            <EditableTitle
              defaultValue={name}
              onSave={handleSaveClick}
              id={id}
              setActive={setActiveFieldId}
              disabled={activeFieldId && id !== activeFieldId}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  ) : null;
};
