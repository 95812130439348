import styled from 'styled-components';

export const Table = styled.table`
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: column;

  tr {
    display: flex;
  }
  th,
  td {
    flex: 1;
  }
`;
